import API from '@/api'
import { set } from 'lockr'
import Bus from '@/assets/ligature.js'
import { DOM } from '@/utils/dom.js'
import dayjs from 'dayjs'
import _ from 'lodash'
import CesiumSpace from '@/utils/cesiumSpace'
import * as turf from '@turf/turf';
import { parse } from 'terraformer-wkt-parser'

let data_source = null
let uav_data = null
let prePosition = new Cesium.Cartesian3(0, 0, 0)
let currentEntity = null
let receiveCount = 0
let receiveDataList = []
let leapIndex = 0
let g_last = new Date().getTime()
let g_sum = 0
let currentOnLinePolyLine = null
let currentOnLineModel = null
let headerFlag = 0
let currentPolylineEntity = null
// 平滑处理的时候，上一个时刻的最后一个点和倒数第一个点
let smoothPreFirstLast = null;
let smoothPreSecondLast = null;
let lerpPointC3 = null;
let sameFlag = false;
let preGSumP = 0;
let polyLinePreviewLng = 0;
let polyLinePreviewLat = 0;
let polyLinePreviewAlt = 0;
let originWSData = [];
let airLineDistance = -1;

class Parser {
  static uav_data_parser(metadata) {
    let data = JSON.parse(metadata)
    return data
  }
}

function tree2list(tree, list = []) {
  tree.forEach((item) => {
    if (item.devices && item.devices.length) {
      item.devices.forEach((d) => list.push(d))
    }
    if (item.child && item.child.length) {
      let arr = tree2list(item.child)
      arr.forEach((d) => list.push(d))
    }
  })
  return list
}

function tree2list_org(tree, list = []) {
  tree.forEach((item) => {
    list.push(item)
    if (item.child && item.child.length) {
      // 为了不失去引用，所以写成遍历然后 push
      let arr = tree2list_org(item.child)
      arr.forEach((_item) => list.push(_item))
    }
  })
  return list
}

export default {
  //请求无人机控制权限
  async jursdiction() {
    let res = await API.FCKERNEL.checkUavControl({
      deviceHardId: this.uav.NoticeData.deviceHardId
    })
    if (res.code == 200) {
      this.$message.success(res.msg)
      this.uav.NoticeFlag = false
    }
  },
  // 初始化地图数据源
  async init_data_source() {
    data_source = new Cesium.CustomDataSource('FCKERNEL_LAYER')
    await viewer.dataSources.add(data_source)
  },
  // 销毁地图数据容器
  destroy_data_source() {
    if (data_source) {
      data_source.entities.removeAll()
      viewer.dataSources.remove(data_source, true)
      data_source = null
    }
  },
  /** 无人机列表搜索
   *@Description: 无人机列表搜索
   *@Date: 2023-10-16 14:21:57
   *@Params1:
   *@Return1:
   */
  async list(search) {
    let loadingInstance = DOM.createLoading('正在加载数据', 'uavListId')
    // let list = await API.FCKERNEL.ListUAV({stationType: 1});
    let list = await API.DEVICE.List({ ...search, cate: 1 })
    console.log(list, '无人机列表')
    // return;
    // let list = require("./uavListData").default.data;
    if (list && list.length && list[0].child) {
      list[0].child.sort(function (a, b) {
        return a.sort - b.sort
      })
    }
    // return;

    function each(tree, flag) {
      return tree.map((item) => {
        return {
          ...item,
          collapse: flag,
          devices:
            item.devices && item.devices.length
              ? item.devices.map((device) => {
                return {
                  ...device,
                  _location: false,
                  _video: false,
                  _airway: false,
                  _control: false,
                  org_id: item.id
                  // reportStatus: 1
                }
              })
              : [],
          child: item.child && item.child.length ? each(item.child, true) : []
        }
      })
    }

    if (list && list.length) {
      list = each(list)
    }
    this.uav.list = list || []
    this.uav.list.forEach(v => {
      console.log('vvvvvv', v);
    })
    loadingInstance.close()
    // 去掉高度模式
    window.viewer.scene.globe.depthTestAgainstTerrain = false;
  },
  // mqtt 订阅
  subscribe(topic, callbck) {
    this.mqtt_subscribe(topic, callbck)
  },
  // 初始化地面站 ws 数据链接
  init_ws() {
    console.log('this', this);

    let { user_info } = this
    let _this = this
    let username = user_info.username
    let token = user_info['mmc-identity'] + user_info['FLYINGSESSIONID']
    // console.log('ws发送给后端的token', user_info);
    // console.log('ws发送给后端的token', token);

    let appId = user_info.appid
    let ws_url = process.env.VUE_APP_WS_URL
    let ws = (this.uav.ws = new WebSocket(ws_url))
    let flag = 0

    ws.onopen = (e) => {
      console.log(`飞行监控页面地面站 webSocket: ${ws_url} 连接成功`, e)
      ws.send(
        JSON.stringify({
          type: 100,
          systemCode: 'mmc',
          state: 1,
          username,
          token,
          appId
        })
      )
      this.$store.commit('device/SET_WS_STATE', this.uav.ws.readyState);
    }
    ws.onmessage = (e) => {
      // this.$store.commit('device/SET_WS_STATE', this.uav.ws.readyState);
      // console.log(`飞行监控无人机飞行websocket${ws_url}接收数据`, e)
      let edata = JSON.parse(e.data);
      // console.log('edata', edata);

      let flag = _.find(originWSData, { 'deviceHardId': edata.deviceHardId });
      if (!flag && edata.deviceHardId) {
        originWSData.push(edata);
      }
      // console.log('originWSData', originWSData);
      /************************************* 平滑处理开始 ***********************************************/
      /************************************* 平滑处理结束 ***********************************************/
      // 鹰视监听禁飞区
      if (!this.uav.control.device || this.uav.control.device?.stationType == 1) {
        // console.log('地图上没有飞机');
        // console.log('飞控链路数据，地面站数据继续传输但是不处理');
        return
      }
      let metadata = null
      try {
        metadata = JSON.parse(e.data)
        // console.log(metadata, '飞行监控无人机数据');
      } catch (e) {
        console.error(e.data)
      }
      if (metadata && metadata.msgnum === 4120) {
        _this.$el_message('admin账号控制中，请稍后控制！', () => { }, 'error')
      }
      if (metadata && metadata.type === 300) {
        // _this.$el_message('执行成功！')
      }
      if (metadata && metadata.type === 300 && metadata.cmdFunction == 9000) {
        _this.$el_message('航线上传成功！')
        // if (this.takeOff == true) {
        this.fly_take_off()
        // }
      }
      if (metadata && metadata.type === 300 && metadata.cmdFunction == 9001) {
        this.level = metadata.data.RSRPLEVEL
        // this.uav.airway = metadata
        // let {
        //     data: {
        //         points
        //     }
        // } = this.uav.airway
        // this.uav_handleAirLinePreview(points)
      }
      // 监听无人机上锁
      if (metadata && metadata.type === 810) {
        let {
          uav: { control }
        } = this

        let deviceHardId = control.deviceHardId
        if (deviceHardId == metadata.deviceHardId) {
          // this.uav.taskStatus = true
        }
      }
      if (metadata && metadata.msgnum) {
        // 设备上线
        if (metadata.msgnum === 4132) {
          let deviceHardId = metadata && metadata.deviceHardId
          this.uav_update_device_report_status(1, deviceHardId, 'list')
          this.uav_update_device_report_status(1, deviceHardId, 'mountList')
        }
        // 设备离线
        if (metadata.msgnum === 4121) {
          let deviceHardId = metadata && metadata.deviceHardId
          this.uav_update_device_report_status(0, deviceHardId, 'list')
          this.uav_update_device_report_status(0, deviceHardId, 'mountList')
        }
      } else {
        // 无人机上报数据
        if (metadata && metadata.deviceData) {
          // console.log('metadata', metadata.deviceHardId);

          uav_data = JSON.parse(metadata.deviceData)
          // console.log('this.uav.ws', this.uav.ws.readyState);
          // console.log('无人机上报数据：', uav_data);
          // console.log('uav_data.data.uavInfo.groundSpeed', uav_data.data.uavInfo.groundSpeed);
          
          // uav_data.data.uavInfo.groundSpeed = parseFloat(_.random(10.0, 11.0));
          // console.log('uav_data.data.uavInfo.groundSpeed', uav_data.data.uavInfo.groundSpeed);
          // if( uav_data.deviceHardId == '5112003F0034'){
          // console.log('无人机上报数据：', uav_data.data.uavInfo.longitude,uav_data.data.uavInfo.latitude,uav_data.data.uavInfo.altitude);
          // console.log('无人机上报数据：', uav_data.data.uavInfo);
          //   let a = uav_data.data.uavInfo.groundSpeed;
          //   if(a<8){
          //     uav_data.data.uavInfo.groundSpeed = parseFloat(_.random(10.0, 11.0));
          //   }
          // }
          // MMC301R22208068
          // if(uav_data.deviceHardId == 'MMC301R22208068'){
          // console.log('后端推送', uav_data.data.uavInfo.latitude, uav_data.data.uavInfo.longitude);
          // }

          //模拟飞机上升 uav_data && uav_data.deviceHardId
          if (uav_data && uav_data.deviceHardId) {
            if (uav_data.data && uav_data.data.uavInfo) {
              if (this?.uav?.control?.device?.deviceHardId == uav_data.deviceHardId && this.wsShow) {
                // debugger
                // 更新控制面板数据
                this.uav_update_control(this.uav.control.device, uav_data.data.uavInfo)
                //
                this.uav_update_mounts(this.uav.control.device, uav_data.data.mountInfo)
                // 地面站无人机电池更新
                // console.log('?地面站无人机数据', uav_data.data.uavInfo);
                // console.log('?地面站无人机数据', uav_data.data);
                // console.log('?地面站无人机数据', uav_data);
                let dis = uav_data.data.uavInfo.flightDistance - uav_data.data.uavInfo.distanceToHome;
                // console.log('?距离', dis);
                this.uav_update_control_battery(this.uav.control.device, uav_data.data.uavInfo)
                // this.uav_update_control(uav_data)
                if (uav_data.data.uavInfo.push_status) {
                  let push_status = uav_data.data.uavInfo.push_status
                  // 视频推流数据刷新
                  // this.uav_update_device_video_status(push_status, uav_data.deviceHardId, 'mountList')
                  // this.uav_update_device_video_status(push_status, uav_data.deviceHardId, 'list')
                }
                if (this.wsShow) {
                  // 更新位置
                  let lon = uav_data.data.uavInfo.longitude;
                  let lat = uav_data.data.uavInfo.latitude;
                  let height = uav_data.data.uavInfo.height;
                  if (!height) {
                    height = 0;
                  }
                  // console.log('uav_data.data.uavInfo', uav_data.data.uavInfo);
                  if (lon && lat) {
                    // this.uav_update_locations(uav_data)
                    let a = JSON.parse(JSON.parse(e.data).deviceData).data.uavInfo;
                    this.uav_update_locations(uav_data)
                    receiveDataList.push(a)
                    // smoothPreFirstLast = a;
                    leapIndex = 0
                    g_sum = 0
                  }

                  // console.log('更新位置')
                  // let a = JSON.parse(JSON.parse(e.data).deviceData).data.uavInfo;
                  // // let sameFlag = _.isEqual(a, smoothPreFirstLast);
                  // // if(sameFlag){
                  // //   console.log('无人机本次上报数据和上一个时刻上报的数据一样');
                  // // } else {
                  // //   console.log('无人机本次上报数据和上一个时刻上报的数据不一样');  
                  // // }
                  // this.uav_update_locations(uav_data)
                  // receiveDataList.push(a)
                  // // smoothPreFirstLast = a;
                  // leapIndex = 0
                  // g_sum = 0
                }
              }
            }
          }
        }
        if (metadata && metadata.type === 800) {
          // this.uav.imgUrl = metadata.url
          // this.uav.imgVisible = true
        }
      }
    }
    ws.onerror = (error) => {
      console.log(`飞行监控页面地面站 webSocket: ${ws_url} 连接错误`, error)
      this.$store.commit('device/SET_WS_STATE', this.uav.ws.readyState);
    }
    ws.onclose = (data) => {
      console.log(`飞行监控页面地面站 webSocket: ${ws_url} 连接关闭`, data)
      this.$store.commit('device/SET_WS_STATE', this.uav.ws.readyState);
    }
  },
  // 模型位置插值平滑处理 Position smoothing
  smoothingPosition(time, result) {
    // console.log('模型位置插值平滑处理', time)
    if (time && receiveDataList.length > 3) {
      // debugger
      try {
        // 获取最后一个点和倒数第二个点
        // console.log('receiveDataList', receiveDataList)
        let firstLast = receiveDataList.at(-1);
        let secondLast = receiveDataList.at(-2);
        let threeLast = receiveDataList.at(-3);

        // console.log('dateTime1', firstLast.dateTime);
        // console.log('dateTime2', secondLast.dateTime);
        // console.log('dateTime3', threeLast.dateTime);
        let diff = Math.abs(firstLast.dateTime - threeLast.dateTime);
        // console.log('diff', diff);
        // console.log(secondLast, firstLast, 'firstLast')
        let secondPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(secondLast.longitude),
          parseFloat(secondLast.latitude), secondLast.height)
        // console.log(secondPointC3, 'secondPointC3')
        let firstPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(firstLast.longitude),
          parseFloat(firstLast.latitude), firstLast.height)
        if (diff > 100) {
          // return firstPointC3;

          // console.log('firstPointC3', firstPointC3);

          let millsecond = parseInt(firstLast.dateTime) - parseInt(secondLast.dateTime)

          if (millsecond != 0) {
            let a = Cesium.JulianDate.toDate(time)
            let b = a.getTime()
            let c = b - g_last;
            g_sum += c
            if (g_sum > millsecond) {
              g_sum = millsecond;
            }
            let gSumP = g_sum / millsecond;
            preGSumP = gSumP;

            // console.log( 'secondPointC3', secondPointC3 );
            // console.log( 'firstPointC3', firstPointC3 );
            // console.log( 'gSumP', gSumP );
            // console.log( 'millsecond', millsecond );
            // console.log( 'g_sum', g_sum );
            if (g_sum < millsecond + 500) {
              lerpPointC3 = Cesium.Cartesian3.lerp(secondPointC3, firstPointC3, gSumP, new Cesium.Cartesian3())
              // console.log('lerpPointC3', lerpPointC3);
            } else {
              // console.log('lerpPointC3', lerpPointC3);
            }
            g_last = b;

            let gb = CesiumSpace.toDegrees(lerpPointC3);
            // console.log('gb', gb);
            // return lerpPointC3 || firstPointC3;
            if (Cesium.defined(lerpPointC3)) {
              return lerpPointC3;
            }
          } else {
            // return firstPointC3;
          }
        } else {
          if (Cesium.defined(firstPointC3)) {
              return firstPointC3;
          }
        }
        // return firstPointC3
        // return new Cesium.Cartesian3.fromDegrees(108, 30, 10000);
      } catch (error) {
        // console.log(error, 'error')
        // return new Cesium.Cartesian3.fromDegrees(118, 30, 10000)
      } finally {
        // console.log('fffffffffff')
        // return new Cesium.Cartesian3.fromDegrees(118, 30, 10000);
      }
    } else {
      // return new Cesium.Cartesian3.fromDegrees(18, 30, 10000);
    }
    // return new Cesium.Cartesian3.fromDegrees(118, 30, 10000);
  },
  // 模型姿态(俯仰角)插值平滑处理
  smoothingYaw(time, result) {
    if (receiveDataList.length > 3) {
      // console.log('receiveDataList', receiveDataList)
      let firstLast = receiveDataList.at(-1)
      let secondLast = receiveDataList.at(-2)
      // let secondPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(secondLast.longitude), parseFloat(secondLast.latitude), secondLast.altitude)
      let firstPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(firstLast.longitude), parseFloat(firstLast.latitude), firstLast.height)
      let yaw1 = firstLast.yaw
      let yaw2 = secondLast.yaw
      let millsecond = parseInt(firstLast.dateTime) - parseInt(secondLast.dateTime)
      if (millsecond > 100) {
        let a = Cesium.JulianDate.toDate(time)
        let b = a.getTime()
        let c = b - g_last
        g_sum += c
        let gSumP = g_sum / millsecond
        let yawN = yaw2 + Math.abs(yaw1 - yaw2) * gSumP
        let h = Cesium.Math.toRadians(yawN + 90)
        // let h = Cesium.Math.toRadians(yawN + 0)
        // console.log(h*180/Math.PI, 'h')
        let orientation = Cesium.Transforms.headingPitchRollQuaternion(firstPointC3, new Cesium.HeadingPitchRoll(h, 0, 0))
        g_last = b
        // console.log('orientation', orientation);
        if (Cesium.defined(orientation)) {
          return orientation;
        }
        // return orientation
      }

    }
  },
  // TD220 螺旋桨转动
  td220_liftingAirscrew(time, result) {
    if (receiveDataList.length > 0) {
      let firstLast = receiveDataList.at(-1)
      let firstPointC3 = new Cesium.Cartesian3.fromDegrees(parseFloat(firstLast.longitude), parseFloat(firstLast.latitude), firstLast.altitude)
      headerFlag += 2
      if (headerFlag >= 360) headerFlag = 0
      let h = Cesium.Math.toRadians(headerFlag)
      let orientation = Cesium.Transforms.headingPitchRollQuaternion(firstPointC3, new Cesium.HeadingPitchRoll(h, 0, 0))
      return orientation
    }
  },

  async control_ws(cate) {
    // debugger
    // let {
    //     flight_line_id,
    //     id
    // } = this.$store.state.task.controlPanelData;
    let deviceHardId = this.uav.control.device.deviceHardId
    // let taskId = this.$store.state.fckernel.taskId
    // if (!deviceHardId) {
    //     return false
    // }
    let username = this.user_info.username
    // if (cate > 8 && taskId) {} else {
    //     return this.$message.info('请选择航线')
    // }
    if (cate == 11) {
      // 安全降落
      this.$confirm('请确认是否执行安全降落操作?', '安全确认', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        customClass: 'uav_controlPane',
        showClose: false
      })
        .then(() => {
          this.uav_send_ws({
            type: 200,
            systemCode: 'mmc',
            state: 1,
            username,
            data: {
              cmdFunction: 2116
            },
            deviceHardId
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    } else if (cate == 12) {
      // 一键返航
      this.$confirm('请确认是否进行一键返航操作?', '返航确认', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        customClass: 'uav_controlPane',
        showClose: false
      })
        .then(() => {
          this.uav_send_ws({
            type: 200,
            systemCode: 'mmc',
            state: 1,
            username,
            data: {
              cmdFunction: 2112
            },
            deviceHardId
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    } else if (cate == 13) {
      // 航线模式
      this.uav_send_ws({
        type: 200,
        systemCode: 'mmc',
        state: 1,
        username,
        data: {
          cmdFunction: 2115
        },
        deviceHardId
      })
    } else if (cate == 17) {
      // 暂停飞行
      this.uav_send_ws({
        type: 200,
        systemCode: 'mmc',
        state: 1,
        username,
        data: {
          cmdFunction: 2117
        },
        deviceHardId
      })
    }
  },
  /** todo ws 地面站最终指令发送
   *@Description: ws 地面站最终指令发送
   *@Author: name
   *@Date: 2023-12-26 11:04:15
   *@Params1:
   *@Return1:
   */
  send_ws(data) {
    // debugger
    // let a = JSON.stringify(data);
    // console.log(data, 'send_data');
    console.log('ws 地面站最终指令发送', data)
    // debugger
    this.uav.ws && this.uav.ws.send(JSON.stringify(data))
  },
  /** 点击视频、控制面板、接管 按钮
   *@Description: 点击视频、控制面板、接管 按钮
   *@Date: 2023-10-16 14:44:47
   *@Params1: cate 3， 4， 5 分别是视频、控制面板、接管 按钮
   *@Return1:
   */
  async fn(cate, device, orgName) {
    console.log('------------------------------无人机列表点击按钮')
    console.log(cate, 'cate')
    console.log(device, 'device')
    // 如果该无人机是一个正在进行一个飞行任务，那么是否这个无人机就带有一个任务id,即taskID
    if (device?.taskId) {
      // let reskRes = await API.TASK.FlightDetail(device.taskId);
      // console.log('raskRes', reskRes);
      // let points = JSON.parse(reskRes.flightCourseJson).points;
      // this.uav.airLineDistance = 0;
      // this.uav.airLinePoints = points;
      // for (let i = 0; i < points.length - 1; i++) {
      //   let from = turf.point([points[i].lon, points[i].lat]);
      //   let to = turf.point([points[i+1].lon, points[i+1].lat]);
      //   let options = {units: 'kilometers'};
      //   this.uav.airLineDistance += parseFloat(turf.distance(from, to, options) * 1000);
      // }
      // console.log('this.uav.airLineDistance', this.uav.airLineDistance);
    }
  
    // return;
    // 删除之前备降点
    this.uav_deleteStandByPoint()
    // 查询备降点并添加到地图上
    let res = await API.DEVICE.getUavStandByPosition(device.deviceHardId)
    device.forceLandingPoint = res.forceLandingPoint
    res.forceLandingPoint && this.uav_addStandByPoint2Map(device, res.forceLandingPoint)
    // console.log(orgName, 'orgName')
    if (device.stationType == 1) {
      console.log('数据类型：飞控链路mqtt')
      this.wsShow = false
    } else {
      console.log('数据类型：地面站数据')
      this.wsShow = true
    }
    // debugger
    this.uavId = device
    // 判断无人机是否在线
    if (device.reportStatus !== 1) {
      this.$message('无人机不在线')
      return
    }
    // 初始化地图数据源
    if (!data_source) {
      await this.uav_init_data_source()
    }
    // 无人机列表面板显示或者关闭
    cate != 6 && this.collapseFlagfn()
    // 打开创建任务面板
    // this.controlMenuFlag = true;
    // 设置无人机型号
    let uavCate = device.cateName == 'px4' ? 'PX4' : 'APM'
    // let topic = `${uavCate}/RECEIVE/${device.deviceHardId}`;
    // 设置飞控链路订阅topic
    let topic = `uaq20/RECEIVE/${device.deviceHardId}`
    // debugger
    if (cate === 1) {
      // 定位
      device._location = !device._location
      if (device._location) {
        this.uav_show_location(device)
      } else {
        this.uav_hide_location(device)
      }
    } else if (cate === 2) {
      // 航线
      device._airway = !device._airway
      if (device._airway) {
        this.uav_show_airway(device)
      } else {
        this.uav_hide_airway(device)
      }
    } else if (cate === 3) {
      // 视频
      device._video = !device._video
      if (device._video) {
        device._video = true
        this.uav_show_video(device, orgName)
        this.VideoTomapFlag = true
        if (device.stationType == 1) {
          this.wsShow = false
          this.uav_subscribe(topic, () => {
            console.log(`订阅无人机数据：topic:${topic}`)
          })
        } else {
          if (this.mqtt.client) {
            this.mqtt.client.end()
            this.mqtt.client = null
          }
          this.wsShow = true
          this.uav_send_ws({
            type: 1126,
            deviceHardId: device.deviceHardId
          })
        }
        if (this.$store.state.fckernel.taskId) {
          let dataRun = {
            taskId: this.$store.state.fckernel.taskId,
            typeId: 4,
            deviceId: device.id
          }
          let run = API.FCKERNEL.saveDeviceRunMonitorLog(dataRun)
        }
      } else {
        this.VideoTomapFlag = false
        device._video = false
        this.uav_hide_video(device)
      }
    } else if (cate === 4) {
      // 控制面板
      // 清空创建任务的面板
      Bus.$emit('clearCreateTaskPanelData', true)
      // 隐藏地图上旧数据
      this.uav_hide_location_all()
      // 隐藏地图上航线数据
      this.uav_hide_airways_all()
      // 打开控制面板
      device._control = !device._control
      // 关闭创建任务面板
      this.controlMenuFlag = device._control
      if (device.deviceHardId == 'MMC301R22208068') {
        this.uav_a1();
      }
      // debugger
      if (device._control) {
        // 挂载，健康管理，图库工具条初始化
        this.br3ClickedFlag = -1
        // 打开视频窗口
        device._video = true
        this.uav_show_video(device, orgName)
        // 老版本的控制器
        this.uav_show_control(device)
        // 底部信息面板
        this.ControlFlag = true
        Bus.$emit('uav_flag_new_test', true)
        // 位置平滑处理变量数据
        receiveDataList = []
        leapIndex = 0
        g_sum = 0
        // debugger
        // 打开 任务面板
        if (device.stationType == 1) {
          // 飞控链路数据
          this.mqtt_init()
          console.log('该无人机连接mqtt')
          // this.wsShow = false
          this.wsShow = false
          this.uav_subscribe(topic, () => {
            console.log(`订阅无人机数据：topic:${topic}`)
          })
        } else {
          console.log('该无人机连接地面站')
          if (this.mqtt.client) {
            this.mqtt.client.end()
            this.mqtt.client = null
          }
          this.wsShow = true
          this.uav_send_ws({ type: 1126, deviceHardId: device.deviceHardId })
        }
        device._location = false
        this.linevisible = false
        // 创建 entity
        // debugger
        this.uav_show_location(device)
        // this.collapseFlag = true
      } else {
        this.healthData = {}
        this.controlMenuFlag = false
        this.VideoTomapFlag = false
        device._video = false
        this.ControlFlag = false
        this.uav_hide_control(device)
        this.uav_hide_video(device)
      }
    } else if (cate === 5) {
      // 接管无人机
      // debugger
      // 点击接管无人机
      // 查看是否有接管权限
      let res = await API.FCKERNEL.getTakeOverInfo({
        deviceHardId: device.deviceHardId
      })
      // { code : 200, msg : "允许操作无人机！" }
      if (res.code == 201) {
        this.$message.warning(res.msg)
        return false
      } else if (res.code == 200) {
        let res = await API.FCKERNEL.checkUseOperate({
          deviceHardId: device.deviceHardId
        })
        // 检查用户操作
        // debugger
        if (res.code == 201) {
          let text = `是否请求接管 ${orgName} 下的 ${device.deviceName}`
          this.uav.NoticeData.deviceHardId = device.deviceHardId
          this.uav.NoticeData.content = text
          this.uav.NoticeFlag = true
        } else if (res.code == 200) {
          // debugger
          return this.$message.warning(res.msg)
        }
      }
    } else if (cate === 6) {
      // 关闭无人机列表，打开创建任务面板
      console.log('关闭无人机列表，打开创建任务面板')
      if (!device._control) {
        this.$message({
          type: 'warning',
          message: '暂无选择任务，请先点击控制面板选择任务'
        })
        return
      }
      this.controlMenuFlag = true
    }
  },

  a1() {
    let a = [
      110.7773975, 32.6040274, 252.084,
      110.7773981, 32.6040278, 252.231,
      110.7773994, 32.6040282, 252.701,
      110.7773996, 32.6040273, 253.124,
      110.7774, 32.6040259, 256.327,
      110.7773983, 32.6040267, 260.754,
      110.7773982, 32.6040276, 266.41,
      110.7773962, 32.6040305, 272.04,
      110.7773953, 32.6040293, 277.365,
      110.7773951, 32.6040279, 282.13,
      110.7773935, 32.6040265, 286.508,
      110.7773944, 32.6040284, 290.833,
      110.7773951, 32.6040288, 295.241,
      110.7773958, 32.6040289, 299.763,
      110.7773958, 32.6040289, 304.362,
      110.7773958, 32.6040288, 308.937,
      110.7773963, 32.6040289, 313.455,
      110.7773964, 32.604029, 318.076,
      110.7773963, 32.6040288, 322.626,
      110.7773962, 32.6040285, 327.317,
      110.7773965, 32.6040283, 331.983,
      110.7773964, 32.6040284, 336.662,
      110.7773966, 32.6040283, 341.319,
      110.7773968, 32.6040286, 346.901,
      110.7773968, 32.6040287, 350.681,
      110.777397, 32.6040291, 355.4,
      110.7773975, 32.604029, 360.113,
      110.7773972, 32.6040285, 364.741,
      110.7773968, 32.6040283, 369.424,
      110.777397, 32.6040279, 374.091,
      110.7773973, 32.6040284, 378.915,
      110.7773979, 32.6040291, 383.61,
      110.7773977, 32.6040288, 388.31,
      110.7773973, 32.6040291, 393.053,
      110.7773975, 32.6040294, 397.823,
      110.7773984, 32.6040295, 402.465,
      110.777399, 32.6040289, 407.192,
      110.7773991, 32.6040288, 412.103,
      110.7773987, 32.6040289, 417.1,
      110.7773987, 32.6040293, 422.129,
      110.7773991, 32.6040291, 427.2,
      110.7773989, 32.604029, 432.193,
      110.777399, 32.604029, 437.085,
      110.777399, 32.6040284, 442.021,
      110.7773984, 32.6040287, 447.111,
      110.7773981, 32.6040289, 452.018,
      110.7773986, 32.6040292, 456.786,
      110.7773984, 32.604029, 461.464,
      110.7773984, 32.6040287, 465.286,
      110.7773987, 32.6040285, 467.862,
      110.7773988, 32.6040293, 469.496,
      110.7773996, 32.6040298, 470.476,
      110.7774001, 32.6040299, 471.064,
      110.7773998, 32.6040296, 471.461,
      110.7774034, 32.604017, 471.709,
      110.7774152, 32.6039858, 471.93,
      110.7774441, 32.6039517, 472.087,
      110.7774636, 32.6039256, 472.075,
      110.7774896, 32.6038698, 471.99,
      110.7775233, 32.603794, 471.92,
      110.7775601, 32.6037104, 472.07,
      110.7776023, 32.6036095, 472.164,
      110.7776299, 32.6035415, 472.125,
      110.7776639, 32.6034564, 472.077,
      110.7776993, 32.6033716, 472.105,
      110.7777347, 32.6032862, 472.072,
      110.777762, 32.6032187, 472.101,
      110.7778004, 32.6031335, 472.336,
      110.7778203, 32.6030859, 472.17,
      110.7778315, 32.6030518, 471.912,
      110.7778413, 32.6030248, 471.906,
      110.7778537, 32.602997, 471.963,
      110.7778428, 32.6029741, 471.998,
      110.7778223, 32.6029438, 471.968,
      110.7777925, 32.6028813, 472.007,
      110.777762, 32.6027984, 472.101,
      110.7777285, 32.6027136, 472.259,
      110.7776853, 32.6026131, 472.219,
      110.7776489, 32.6025284, 472.156,
      110.7776197, 32.6024595, 472.096,
      110.7775915, 32.6023912, 472.047,
      110.7775501, 32.6022888, 472.056,
      110.7775156, 32.602204, 472.018,
      110.7774807, 32.6021199, 472.007,
      110.7774404, 32.6020185, 472.023,
      110.7774054, 32.6019329, 472.015,
      110.777377, 32.601865, 472.029,
      110.7773415, 32.6017793, 472.133,
      110.7773067, 32.6016944, 472.141,
      110.7772716, 32.6016102, 472.122,
      110.7772298, 32.6015084, 472.058,
      110.7771948, 32.601423, 472.032,
      110.7771671, 32.6013548, 472.112,
      110.7771255, 32.6012534, 472.212,
      110.7770973, 32.6011847, 472.191,
      110.7770619, 32.6011002, 472.101,
      110.7770269, 32.601015, 472.054,
      110.776992, 32.6009299, 472.08,
      110.7769497, 32.6008274, 472.039,
      110.7769214, 32.6007588, 471.982,
      110.7768802, 32.6006582, 471.953,
      110.776853, 32.6005931, 472.044,
      110.7768257, 32.6005293, 472.144,
      110.7768057, 32.6004843, 472.056,
      110.7767932, 32.6004519, 472.01,
      110.776785, 32.6004274, 472.024,
      110.7767779, 32.6004028, 472.029,
      110.7767895, 32.6003751, 472.009,
      110.7768153, 32.6003147, 472.054,
      110.7768339, 32.6002398, 472.036,
      110.7768558, 32.6001524, 472.09,
      110.7768739, 32.6000822, 472.207,
      110.7768992, 32.5999776, 472.182,
      110.7769164, 32.5999064, 472.153,
      110.7769383, 32.5998186, 472.135,
      110.7769649, 32.5997132, 472.102,
      110.7769872, 32.5996254, 472.023,
      110.7770094, 32.5995374, 472.034,
      110.7770273, 32.5994678, 472.062,
      110.7770498, 32.5993797, 472.066,
      110.7770764, 32.5992743, 472.153,
      110.7770945, 32.5992024, 472.187,
      110.7771215, 32.5990973, 472.142,
      110.7771439, 32.5990095, 472.13,
      110.7771662, 32.5989217, 472.118,
      110.7771882, 32.5988337, 472.096,
      110.7772108, 32.5987461, 472.094,
      110.7772336, 32.598658, 472.099,
      110.7772518, 32.5985877, 472.075,
      110.7772788, 32.5984819, 472.04,
      110.7773012, 32.5983938, 472.054,
      110.7773188, 32.5983237, 472.074,
      110.7773409, 32.5982362, 472.062,
      110.7773673, 32.5981311, 472.056,
      110.7773901, 32.5980433, 472.046,
      110.7774081, 32.5979734, 472.052,
      110.7774347, 32.5978682, 472.059,
      110.7774483, 32.5978145, 472.062,
      110.7774757, 32.5977088, 472.082,
      110.7774982, 32.5976207, 472.082,
      110.7775258, 32.5975159, 472.026,
      110.7775438, 32.5974457, 472.036,
      110.7775704, 32.5973396, 472.119,
      110.7775926, 32.5972524, 472.142,
      110.7776127, 32.59718, 472.359,
      110.7776267, 32.5971282, 472.32,
      110.777634, 32.5970915, 472.084,
      110.7776384, 32.5970706, 471.933,
      110.7776454, 32.5970439, 471.9,
      110.7776594, 32.5970262, 471.923,
      110.7776992, 32.5970126, 471.906,
      110.7777575, 32.5969814, 471.895,
      110.7778276, 32.5969339, 471.876,
      110.7779075, 32.5968783, 472.006,
      110.7779926, 32.5968219, 472.179,
      110.7780769, 32.596767, 472.176,
      110.7781617, 32.5967118, 472.143,
      110.7782458, 32.596656, 472.174,
      110.7783317, 32.5965998, 472.202,
      110.7784, 32.5965558, 472.22,
      110.7785005, 32.5964912, 472.192,
      110.778585, 32.5964364, 472.13,
      110.7786708, 32.5963816, 472.136,
      110.7787557, 32.596327, 472.084,
      110.7788413, 32.5962718, 472.094,
      110.7789085, 32.5962283, 472.092,
      110.7790079, 32.5961638, 472.013,
      110.7790756, 32.5961193, 471.976,
      110.7791771, 32.5960534, 471.968,
      110.7792443, 32.5960095, 472.038,
      110.7793454, 32.5959439, 472.113,
      110.7794307, 32.5958887, 472.14,
      110.7795152, 32.5958337, 472.121,
      110.7795835, 32.5957889, 472.085,
      110.7796849, 32.5957238, 472.1,
      110.7797692, 32.5956702, 472.124,
      110.7798542, 32.5956153, 472.123,
      110.7799388, 32.5955608, 472.172,
      110.7800251, 32.5955055, 472.163,
      110.7801092, 32.5954509, 472.312,
      110.7801937, 32.5953958, 472.258,
      110.7802782, 32.5953391, 472.133,
      110.7803636, 32.5952838, 472.066,
      110.780447, 32.5952298, 472.043,
      110.7805315, 32.5951755, 471.993,
      110.7806001, 32.5951309, 471.98,
      110.7807015, 32.5950664, 472.021,
      110.7807862, 32.5950116, 472.057,
      110.7808699, 32.5949572, 472.067,
      110.7809384, 32.5949125, 472.02,
      110.7810221, 32.5948582, 471.99,
      110.7810559, 32.5948362, 472.004,
      110.7811235, 32.5947928, 472.029,
      110.7811235, 32.5947928, 472.029,
      110.7811235, 32.5947928, 472.029,
      110.7811235, 32.5947928, 472.029,
      110.7811235, 32.5947928, 472.029,
      110.7811235, 32.5947928, 472.029,
      110.7811235, 32.5947928, 472.029,
      110.7811235, 32.5947928, 472.029,
      110.7811235, 32.5947928, 472.029,
      110.7811235, 32.5947928, 472.029,
      110.7811406, 32.5947819, 472.044,
      110.7813109, 32.5946712, 472.105,
      110.7815971, 32.5944849, 472.005,
      110.78192, 32.5942771, 472.078,
      110.7822394, 32.5940692, 472.066,
      110.7824764, 32.5939158, 472.098,
      110.7825624, 32.5938605, 472.104,
      110.7826473, 32.593806, 472.126,
      110.7827312, 32.5937519, 472.095,
      110.7828, 32.5937075, 472.028,
      110.7829024, 32.5936419, 472.036,
      110.7829856, 32.5935879, 472.058,
      110.78307, 32.5935332, 472.08,
      110.7831545, 32.5934783, 472.093,
      110.7832404, 32.5934227, 472.132,
      110.7833253, 32.5933677, 472.109,
      110.7834092, 32.5933126, 472.103,
      110.7834926, 32.5932577, 472.03,
      110.7835763, 32.5932035, 472.008,
      110.7836448, 32.5931593, 472.043,
      110.7837469, 32.5930939, 472.091,
      110.7838146, 32.5930501, 472.076,
      110.7839158, 32.5929844, 472.055,
      110.7840005, 32.5929292, 472.06,
      110.7840847, 32.5928747, 472.087,
      110.7841531, 32.5928305, 472.098,
      110.784254, 32.5927648, 472.098,
      110.7843386, 32.5927099, 472.088,
      110.7844232, 32.5926547, 472.099,
      110.784508, 32.5925999, 472.138,
      110.7845782, 32.5925559, 472.375,
      110.7846285, 32.5925243, 472.274,
      110.7846636, 32.5924994, 472.034,
      110.7846962, 32.5924772, 471.898,
      110.7847336, 32.5924585, 471.938,
      110.7847883, 32.5924476, 471.892,
      110.7848693, 32.5924268, 471.833,
      110.7849677, 32.5923959, 471.964,
      110.7850681, 32.5923647, 472.209,
      110.7851494, 32.5923401, 472.238,
      110.7852688, 32.5923038, 472.159,
      110.7853493, 32.5922794, 472.111,
      110.7854703, 32.5922425, 472.129,
      110.7855515, 32.592218, 472.135,
      110.785672, 32.592182, 472.072,
      110.7857719, 32.592152, 472.082,
      110.7858732, 32.5921209, 472.125,
      110.7859538, 32.5920965, 472.136,
      110.7860742, 32.5920601, 472.142,
      110.786174, 32.5920294, 472.133,
      110.7862744, 32.5919993, 472.083,
      110.7863752, 32.591969, 472.052,
      110.7864545, 32.5919448, 472.047,
      110.786576, 32.5919077, 472.053,
      110.7866757, 32.5918778, 472.057,
      110.7867764, 32.5918473, 472.059,
      110.7868764, 32.5918172, 472.04,
      110.7869772, 32.5917865, 472.04,
      110.7870777, 32.5917564, 472.064,
      110.7871788, 32.591726, 472.08,
      110.7872784, 32.5916957, 472.1,
      110.7873791, 32.5916651, 472.138,
      110.7874801, 32.5916347, 472.106,
      110.7875789, 32.591605, 472.035,
      110.7876792, 32.5915743, 472.054,
      110.7877803, 32.5915438, 472.09,
      110.7878821, 32.5915136, 472.091,
      110.7879823, 32.5914836, 472.098,
      110.7880831, 32.5914532, 472.088,
      110.788183, 32.5914227, 472.092,
      110.7882826, 32.5913923, 472.086,
      110.7883836, 32.5913616, 472.063,
      110.7884831, 32.5913314, 472.048,
      110.7885833, 32.5913006, 472.054,
      110.7886839, 32.5912701, 472.075,
      110.7887858, 32.5912391, 472.097,
      110.7888856, 32.5912089, 472.065,
      110.7889861, 32.591179, 472.055,
      110.7890866, 32.5911489, 472.048,
      110.7891868, 32.5911186, 471.993,
      110.7892861, 32.5910887, 471.994,
      110.7893673, 32.591064, 472.033,
      110.7894873, 32.5910276, 472.108,
      110.7895889, 32.5909966, 472.163,
      110.7896891, 32.5909661, 472.14,
      110.7897901, 32.5909362, 472.083,
      110.7898898, 32.5909062, 472.043,
      110.7899908, 32.590876, 472.059,
      110.7900912, 32.5908456, 472.108,
      110.790192, 32.5908148, 472.136,
      110.7902924, 32.5907846, 472.1,
      110.7903929, 32.5907543, 472.073,
      110.7904931, 32.5907238, 472.064,
      110.7905939, 32.5906934, 472.082,
      110.7906951, 32.5906632, 472.106,
      110.7907958, 32.5906331, 472.08,
      110.7908954, 32.590603, 472.055,
      110.7909966, 32.5905727, 472.086,
      110.7910967, 32.5905423, 472.084,
      110.7911971, 32.5905117, 472.079,
      110.791277, 32.5904874, 472.1,
      110.7913985, 32.5904503, 472.13,
      110.7914989, 32.5904197, 472.093,
      110.7915983, 32.5903896, 472.063,
      110.7916993, 32.5903586, 472.027,
      110.7918006, 32.5903278, 472.05,
      110.7919011, 32.5902979, 472.065,
      110.7920018, 32.5902687, 472.121,
      110.7921016, 32.590238, 472.133,
      110.792203, 32.5902071, 472.128,
      110.792303, 32.5901772, 472.161,
      110.7924029, 32.5901469, 472.127,
      110.7925032, 32.5901159, 472.096,
      110.7926029, 32.5900859, 472.102,
      110.7927038, 32.5900545, 472.034,
      110.7928045, 32.5900245, 472.02,
      110.7929053, 32.5899942, 472.024,
      110.7930062, 32.5899641, 472.023,
      110.7931064, 32.5899337, 472.04,
      110.7932062, 32.5899038, 472.036,
      110.7933079, 32.5898734, 472.072,
      110.7934073, 32.5898431, 472.096,
      110.7935088, 32.5898122, 472.103,
      110.7936099, 32.5897821, 472.134,
      110.7937095, 32.5897521, 472.115,
      110.7938104, 32.5897207, 472.172,
      110.793911, 32.5896903, 472.234,
      110.7940113, 32.589658, 472.126,
      110.7940914, 32.589633, 472.066,
      110.7942113, 32.5895975, 472.067,
      110.7943115, 32.5895672, 472.119,
      110.7944119, 32.5895362, 472.152,
      110.7945135, 32.5895048, 472.081,
      110.7945939, 32.589481, 472.065,
      110.7947128, 32.5894453, 472.039,
      110.794814, 32.5894156, 471.984,
      110.7949144, 32.5893854, 472.054,
      110.7950165, 32.589355, 472.149,
      110.7951174, 32.5893248, 472.083,
      110.7952182, 32.5892948, 472.009,
      110.795318, 32.5892647, 472.026,
      110.7954175, 32.589235, 472.012,
      110.7955183, 32.5892043, 472.049,
      110.7956201, 32.5891736, 472.124,
      110.7957197, 32.5891436, 472.123,
      110.7958208, 32.5891132, 472.102,
      110.7959197, 32.589083, 472.086,
      110.7960209, 32.5890524, 472.122,
      110.7961206, 32.5890217, 472.133,
      110.7962215, 32.5889908, 472.178,
      110.7963217, 32.5889604, 472.149,
      110.7964224, 32.5889298, 472.095,
      110.7965234, 32.5888991, 472.1,
      110.7966238, 32.5888683, 472.098,
      110.7967246, 32.5888381, 472.037,
      110.7968249, 32.5888082, 471.99,
      110.7969254, 32.5887781, 471.963,
      110.7970247, 32.5887475, 472.021,
      110.7971245, 32.5887166, 472.062,
      110.797226, 32.5886862, 472.093,
      110.7973262, 32.5886558, 472.093,
      110.7974269, 32.5886253, 472.098,
      110.7975277, 32.5885946, 472.099,
      110.7976278, 32.5885641, 472.102,
      110.7977279, 32.5885344, 472.109,
      110.7978094, 32.5885098, 472.109,
      110.7979288, 32.5884733, 472.059,
      110.7980298, 32.5884425, 472.045,
      110.7981301, 32.588412, 472.056,
      110.7982307, 32.5883816, 472.081,
      110.7983315, 32.5883512, 472.084,
      110.7984118, 32.5883269, 472.082,
      110.7985322, 32.5882906, 472.081,
      110.7986315, 32.5882604, 472.042,
      110.7987326, 32.5882297, 472.054,
      110.798834, 32.5881991, 472.075,
      110.7989344, 32.5881689, 472.09,
      110.7990343, 32.5881391, 472.105,
      110.7991346, 32.5881093, 472.086,
      110.7992352, 32.5880791, 472.096,
      110.7993363, 32.5880485, 472.093,
      110.7994365, 32.5880185, 472.037,
      110.799538, 32.5879882, 472.034,
      110.7996387, 32.5879576, 472.08,
      110.7997382, 32.5879275, 472.119,
      110.7998381, 32.5878974, 472.112,
      110.7999394, 32.5878666, 472.123,
      110.8000399, 32.5878355, 472.102,
      110.8001407, 32.5878049, 472.076,
      110.8002404, 32.5877749, 472.075,
      110.8003412, 32.5877445, 472.068,
      110.8004416, 32.5877142, 472.085,
      110.800482, 32.5877021, 472.089,
      110.800482, 32.5877021, 472.089,
      110.800482, 32.5877021, 472.089,
      110.800482, 32.5877021, 472.089,
      110.800482, 32.5877021, 472.089,
      110.800482, 32.5877021, 472.089,
      110.800482, 32.5877021, 472.089,
      110.8005422, 32.5876842, 472.09,
      110.8007828, 32.5876107, 472.04,
      110.8011255, 32.5875082, 472.129,
      110.8014666, 32.5874039, 472.106,
      110.8016478, 32.5873494, 471.968,
      110.8017462, 32.5873192, 471.957,
      110.8018471, 32.5872881, 471.981,
      110.801948, 32.587258, 472.033,
      110.8020485, 32.5872276, 472.044,
      110.8021489, 32.5871972, 472.042,
      110.8022497, 32.5871671, 472.053,
      110.8023495, 32.5871368, 472.123,
      110.802449, 32.5871065, 472.123,
      110.8025499, 32.5870758, 472.112,
      110.8026507, 32.5870458, 472.099,
      110.8027513, 32.5870158, 472.08,
      110.8028517, 32.5869857, 472.019,
      110.8029523, 32.5869553, 472.01,
      110.803053, 32.5869245, 472.06,
      110.8031532, 32.5868942, 472.052,
      110.8032535, 32.5868641, 472.112,
      110.8033551, 32.5868332, 472.146,
      110.803454, 32.5868038, 472.061,
      110.8035551, 32.5867738, 472.053,
      110.8036556, 32.586742, 472.133,
      110.8037562, 32.5867108, 472.18,
      110.8038554, 32.586681, 472.086,
      110.8039158, 32.5866626, 472.054,
      110.8040559, 32.5866206, 472.125,
      110.8041584, 32.5865895, 472.113,
      110.804259, 32.5865589, 472.113,
      110.8043593, 32.5865294, 472.106,
      110.8044595, 32.5864989, 472.008,
      110.8045609, 32.5864692, 472.014,
      110.8046622, 32.5864385, 472.1,
      110.8047624, 32.5864079, 472.149,
      110.8048626, 32.5863772, 472.134,
      110.804962, 32.5863474, 472.196,
      110.8050621, 32.586317, 472.202,
      110.8051631, 32.5862865, 472.128,
      110.8052638, 32.5862561, 472.051,
      110.8053643, 32.5862257, 472.035,
      110.8054641, 32.586195, 472.008,
      110.8055632, 32.5861651, 472.014,
      110.8056635, 32.5861352, 471.993,
      110.805765, 32.5861047, 472.008,
      110.8058655, 32.5860744, 472.051,
      110.8059652, 32.586044, 472.067,
      110.8060665, 32.5860132, 472.038,
      110.806167, 32.5859828, 472.012,
      110.8062656, 32.5859533, 472.021,
      110.8063663, 32.5859226, 472.036,
      110.8064665, 32.5858922, 472.054,
      110.8065673, 32.5858613, 472.065,
      110.8066677, 32.5858306, 472.138,
      110.8067679, 32.5858002, 472.187,
      110.8068696, 32.5857695, 472.257,
      110.8069702, 32.5857393, 472.244,
      110.8070703, 32.5857086, 472.064,
      110.8071702, 32.5856785, 471.993,
      110.8072695, 32.5856483, 472.044,
      110.807371, 32.5856173, 472.113,
      110.8074723, 32.5855868, 472.147,
      110.8075729, 32.5855575, 472.148,
      110.8076742, 32.5855277, 472.064,
      110.8077744, 32.5854969, 472.069,
      110.8078745, 32.5854666, 472.11,
      110.8079754, 32.5854367, 472.125,
      110.8080966, 32.5854007, 472.157,
      110.8081772, 32.5853765, 472.163,
      110.8082777, 32.585346, 472.021,
      110.8083764, 32.5853153, 472.004,
      110.8084773, 32.5852848, 472,
      110.8085784, 32.5852547, 472.055,
      110.8086789, 32.5852245, 472.094,
      110.8087783, 32.5851951, 472.08,
      110.8088782, 32.5851642, 471.951,
      110.8089779, 32.5851337, 471.987,
      110.8090781, 32.5851034, 472.068,
      110.8091791, 32.5850725, 472.116,
      110.8092597, 32.585048, 472.113,
      110.8092597, 32.585048, 472.113,
      110.8094602, 32.5849874, 472.075,
      110.8095802, 32.5849511, 472.087,
      110.8096409, 32.584933, 472.089,
      110.8097823, 32.5848905, 472.094,
      110.8098625, 32.5848664, 472.098,
      110.8099828, 32.5848305, 472.087,
      110.8100632, 32.5848066, 472.077,
      110.8101027, 32.5847947, 472.08,
      110.8101027, 32.5847947, 472.08,
      110.8101027, 32.5847947, 472.08,
      110.8101027, 32.5847947, 472.08,
      110.8101027, 32.5847947, 472.08,
      110.8101027, 32.5847947, 472.08,
      110.8102235, 32.5847581, 472.104,
      110.8104661, 32.5846849, 472.089,
      110.8108076, 32.5845822, 472.091,
      110.8110882, 32.5844978, 472.054,
      110.8111897, 32.5844672, 472.062,
      110.8112906, 32.5844371, 472.077,
      110.8113308, 32.584425, 472.093,
      110.8113499, 32.5844193, 472.098,
      110.8113708, 32.5844129, 472.101,
      110.8114102, 32.584401, 472.092,
      110.811632, 32.5843342, 472.052,
      110.8119134, 32.5842494, 472.07,
      110.8119939, 32.5842256, 472.102,
      110.8120944, 32.5841952, 472.066,
      110.8122146, 32.5841589, 472.014,
      110.8122948, 32.5841348, 471.998,
      110.812396, 32.5841049, 472.071,
      110.8125167, 32.5840688, 472.283,
      110.8125962, 32.5840449, 472.315,
      110.8126978, 32.5840143, 472.195,
      110.8127987, 32.5839831, 472.085,
      110.8128996, 32.5839525, 472.022,
      110.8130195, 32.5839166, 472.019,
      110.8130995, 32.5838924, 472.03,
      110.8132188, 32.5838563, 471.936,
      110.8132993, 32.5838321, 471.936,
      110.8134197, 32.5837956, 472.039,
      110.8135009, 32.5837705, 472.113,
      110.8136026, 32.5837405, 472.155,
      110.8137029, 32.5837098, 472.14,
      110.8138037, 32.5836793, 472.114,
      110.813924, 32.5836435, 472.218,
      110.8140036, 32.5836194, 472.111,
      110.8141043, 32.5835882, 472.008,
      110.8142035, 32.5835585, 471.977,
      110.8143048, 32.5835277, 472.025,
      110.8144063, 32.5834974, 472.075,
      110.814507, 32.5834676, 472.098,
      110.8146073, 32.5834378, 472.098,
      110.8147081, 32.5834072, 472.125,
      110.8148074, 32.5833773, 472.115,
      110.8149087, 32.5833469, 472.075,
      110.8150088, 32.5833166, 472.064,
      110.81513, 32.58328, 472.072,
      110.8152295, 32.5832501, 472.095,
      110.8153106, 32.5832257, 472.102,
      110.8154112, 32.5831952, 472.092,
      110.8155119, 32.5831651, 472.066,
      110.8156119, 32.583135, 472.085,
      110.8157136, 32.5831043, 472.134,
      110.8158142, 32.5830742, 472.145,
      110.815914, 32.5830442, 472.088,
      110.8160149, 32.5830136, 472.07,
      110.816115, 32.5829832, 472.081,
      110.8162147, 32.582953, 472.048,
      110.8163364, 32.5829163, 472.08,
      110.8164161, 32.5828921, 472.078,
      110.816517, 32.582862, 472.152,
      110.8166179, 32.5828319, 472.137,
      110.8167389, 32.5827954, 472.098,
      110.8168196, 32.5827709, 472.114,
      110.8169399, 32.5827343, 472.073,
      110.8170206, 32.5827097, 472.04,
      110.8171203, 32.5826801, 472.049,
      110.8172208, 32.58265, 472.087,
      110.8173425, 32.5826138, 472.09,
      110.8174233, 32.5825899, 472.094,
      110.8175232, 32.5825601, 472.084,
      110.8176237, 32.5825299, 472.009,
      110.8177247, 32.5824997, 471.982,
      110.8178251, 32.5824692, 472.009,
      110.8179263, 32.5824387, 472.058,
      110.8180261, 32.5824085, 472.108,
      110.8181272, 32.5823781, 472.089,
      110.8182483, 32.5823414, 472.077,
      110.8183278, 32.5823174, 472.103,
      110.8184272, 32.5822877, 472.133,
      110.8185295, 32.5822573, 472.119,
      110.8186298, 32.5822268, 472.098,
      110.81875, 32.5821902, 472.076,
      110.818829, 32.5821668, 472.07,
      110.8189307, 32.5821356, 472.062,
      110.8190515, 32.5820997, 472.155,
      110.8191517, 32.5820693, 472.1,
      110.8192325, 32.5820444, 472.016,
      110.8193522, 32.5820083, 471.97,
      110.8194321, 32.5819846, 472.027,
      110.8195327, 32.5819544, 472.06,
      110.8196545, 32.5819184, 471.975,
      110.819734, 32.5818943, 472.023,
      110.8198558, 32.5818576, 472.094,
      110.8199362, 32.5818339, 472.109,
      110.8200357, 32.5818045, 472.077,
      110.8201361, 32.5817746, 472.072,
      110.8202577, 32.5817379, 472.081,
      110.8203372, 32.5817138, 472.074,
      110.8204383, 32.5816829, 472.046,
      110.8205396, 32.5816527, 472.038,
      110.8206405, 32.5816224, 472.12,
      110.8207614, 32.5815853, 472.197,
      110.8208404, 32.5815609, 472.155,
      110.8209607, 32.5815248, 472.079,
      110.8210421, 32.5815001, 472.091,
      110.8211426, 32.5814694, 472.102,
      110.8212423, 32.5814392, 472.05,
      110.8213421, 32.5814093, 472.003,
      110.8214424, 32.5813798, 472.032,
      110.8215415, 32.5813506, 472.073,
      110.8216429, 32.5813206, 472.062,
      110.8217629, 32.5812843, 472.052,
      110.8218433, 32.5812602, 472.069,
      110.8219444, 32.5812295, 472.075,
      110.8220247, 32.581205, 472.084,
      110.8221454, 32.5811692, 472.11,
      110.8222462, 32.5811385, 472.133,
      110.8223666, 32.5811023, 472.112,
      110.8224472, 32.5810784, 472.094,
      110.8225675, 32.5810415, 472.161,
      110.8226678, 32.5810106, 472.199,
      110.8227678, 32.58098, 472.172,
      110.8228476, 32.5809555, 472.095,
      110.8229474, 32.5809251, 472.021,
      110.8230674, 32.5808886, 472.052,
      110.8231693, 32.5808575, 472.048,
      110.8232488, 32.5808339, 472.032,
      110.8233501, 32.5808037, 472.029,
      110.8234707, 32.5807678, 472.042,
      110.8235714, 32.5807378, 472.052,
      110.8236522, 32.5807138, 472.073,
      110.8237717, 32.5806777, 472.117,
      110.8238529, 32.5806529, 472.111,
      110.823953, 32.5806225, 472.074,
      110.8240729, 32.5805865, 472.058,
      110.8241525, 32.5805626, 472.093,
      110.8242731, 32.5805259, 472.116,
      110.8243749, 32.5804952, 472.099,
      110.8244541, 32.5804714, 472.094,
      110.824575, 32.5804351, 472.055,
      110.824655, 32.5804109, 472.049,
      110.8247561, 32.5803806, 472.054,
      110.8248571, 32.5803504, 472.097,
      110.8249776, 32.5803142, 472.126,
      110.8250581, 32.58029, 472.122,
      110.8251774, 32.5802537, 472.044,
      110.8252585, 32.580229, 472.048,
      110.8253793, 32.5801922, 472.124,
      110.8254784, 32.5801618, 472.115,
      110.8255589, 32.5801375, 472.054,
      110.8256581, 32.5801073, 472.003,
      110.82576, 32.5800771, 472.046,
      110.8258606, 32.580047, 472.071,
      110.8259607, 32.5800175, 472.035,
      110.8260613, 32.5799872, 472.03,
      110.8261624, 32.5799566, 472.057,
      110.8262824, 32.5799204, 472.115,
      110.8263836, 32.5798891, 472.126,
      110.8264833, 32.5798583, 472.078,
      110.8265833, 32.5798276, 472.08,
      110.8266837, 32.5797978, 472.128,
      110.8267644, 32.5797735, 472.126,
      110.8268647, 32.5797434, 472.09,
      110.8269852, 32.5797088, 472.038,
      110.8270852, 32.5796793, 472.031,
      110.8271655, 32.5796543, 472.087,
      110.8272862, 32.5796152, 472.19,
      110.8273876, 32.5795854, 472.176,
      110.8274675, 32.5795623, 472.045,
      110.8275676, 32.5795321, 472.019,
      110.8276684, 32.579501, 472.145,
      110.8277702, 32.5794709, 472.146,
      110.8278911, 32.5794354, 472.094,
      110.827991, 32.5794057, 472.057,
      110.8280706, 32.5793817, 472.034,
      110.8281706, 32.5793514, 472.035,
      110.8282915, 32.5793152, 472.044,
      110.8283925, 32.5792847, 472.075,
      110.8284931, 32.5792544, 472.119,
      110.8285946, 32.5792236, 472.154,
      110.8286942, 32.5791925, 472.215,
      110.8287948, 32.579162, 472.182,
      110.8288946, 32.5791319, 472.194,
      110.8289746, 32.579108, 472.135,
      110.8290949, 32.5790715, 472.043,
      110.8291955, 32.5790415, 472.067,
      110.829276, 32.5790178, 472.095,
      110.8293956, 32.5789815, 472.004,
      110.829497, 32.5789511, 471.988,
      110.8295967, 32.578921, 472.033,
      110.8296775, 32.5788968, 472.041,
      110.8297987, 32.5788604, 472.045,
      110.8298995, 32.5788302, 472.062,
      110.8299988, 32.5788004, 472.053,
      110.8300998, 32.5787698, 472.027,
      110.8301994, 32.5787401, 471.99,
      110.8302796, 32.5787157, 472.023,
      110.8304015, 32.5786786, 472.131,
      110.8304816, 32.5786539, 472.14,
      110.8306012, 32.5786184, 472.14,
      110.8307018, 32.5785881, 472.086,
      110.8308026, 32.5785575, 472.059,
      110.8309022, 32.5785272, 472.039,
      110.8310028, 32.5784972, 472.032,
      110.8310834, 32.578473, 472.024,
      110.8312039, 32.5784369, 472.071,
      110.8312844, 32.5784127, 472.106,
      110.8314056, 32.578376, 472.133,
      110.8315056, 32.5783459, 472.114,
      110.8316062, 32.5783154, 472.087,
      110.8316864, 32.5782911, 472.082,
      110.8318065, 32.5782547, 472.07,
      110.8319072, 32.5782249, 472.037,
      110.8320086, 32.5781948, 472.06,
      110.8321091, 32.5781646, 472.131,
      110.8322089, 32.578134, 472.163,
      110.8323104, 32.5781031, 472.131,
      110.8324108, 32.578073, 472.089,
      110.8325097, 32.578043, 472.071,
      110.8326112, 32.5780125, 472.071,
      110.8327126, 32.5779828, 472.086,
      110.832813, 32.5779524, 472.112,
      110.8329129, 32.5779222, 472.085,
      110.8329926, 32.5778982, 472.05,
      110.8331119, 32.5778623, 472.038,
      110.8332129, 32.577832, 472.054,
      110.8333145, 32.5778016, 472.055,
      110.8333939, 32.5777773, 472.104,
      110.8335143, 32.5777409, 472.09,
      110.8336156, 32.5777106, 472.057,
      110.8337161, 32.5776807, 472.048,
      110.8337968, 32.5776565, 472.056,
      110.8339173, 32.5776204, 472.128,
      110.8340192, 32.5775897, 472.147,
      110.8341192, 32.5775589, 472.146,
      110.8342194, 32.5775288, 472.104,
      110.834319, 32.5774988, 472.071,
      110.8344193, 32.5774685, 472.068,
      110.8345201, 32.5774385, 472.063,
      110.8346205, 32.5774083, 472.076,
      110.8347201, 32.5773782, 472.07,
      110.8348208, 32.5773476, 472.072,
      110.8349208, 32.5773175, 472.075,
      110.8350227, 32.5772869, 472.117,
      110.8351234, 32.5772565, 472.158,
      110.8352242, 32.5772261, 472.154,
      110.8353235, 32.577196, 472.133,
      110.835424, 32.577166, 472.095,
      110.8355239, 32.5771361, 472.09,
      110.8356245, 32.577105, 472.124,
      110.8357249, 32.5770753, 472.084,
      110.8358258, 32.5770448, 472.087,
      110.8359264, 32.5770144, 472.067,
      110.8360272, 32.5769836, 472.068,
      110.836128, 32.5769534, 472.109,
      110.8362287, 32.5769236, 472.095,
      110.8363282, 32.5768936, 472.056,
      110.8364289, 32.5768638, 472.035,
      110.8365307, 32.5768338, 472.039,
      110.8366318, 32.5768039, 472.108,
      110.8367309, 32.5767731, 472.119,
      110.8368317, 32.5767432, 472.196,
      110.8369328, 32.5767129, 472.178,
      110.8370329, 32.5766825, 472.106,
      110.837133, 32.5766525, 472.02,
      110.8372346, 32.5766223, 471.945,
      110.8373353, 32.576592, 472.004,
      110.8374352, 32.5765625, 472.034,
      110.8375346, 32.5765322, 472.022,
      110.8376354, 32.5765012, 472.04,
      110.8377365, 32.576471, 472.094,
      110.8378372, 32.5764404, 472.091,
      110.8379176, 32.576416, 472.096,
      110.8380379, 32.57638, 472.096,
      110.8381378, 32.57635, 472.047,
      110.8382379, 32.5763198, 472.015,
      110.838338, 32.5762896, 471.995,
      110.838437, 32.5762599, 471.983,
      110.8385391, 32.5762294, 472.035,
      110.8386397, 32.5761995, 472.118,
      110.8387211, 32.5761753, 472.179,
      110.8388217, 32.5761447, 472.154,
      110.8389421, 32.5761075, 472.185,
      110.8390428, 32.5760769, 472.234,
      110.8391429, 32.5760472, 472.237,
      110.8392428, 32.5760171, 472.143,
      110.8393436, 32.5759862, 472.01,
      110.8394446, 32.575956, 472.002,
      110.8395446, 32.575926, 472.071,
      110.8396435, 32.5758959, 472.145,
      110.8397453, 32.5758658, 472.024,
      110.8398454, 32.5758357, 471.996,
      110.8399347, 32.5758086, 472.279,
      110.8399984, 32.5757888, 472.293,
      110.8400445, 32.5757755, 472.069,
      110.8400859, 32.5757638, 471.926,
      110.8401269, 32.5757498, 471.965,
      110.8401667, 32.57572, 471.865,
      110.8402281, 32.5756783, 471.811,
      110.8402281, 32.5756783, 471.811,
      110.8403445, 32.5756098, 471.993,
      110.8404811, 32.5755259, 472.251,
      110.840566, 32.5754721, 472.178,
      110.8406349, 32.575429, 472.113,
      110.8407376, 32.5753654, 472.041,
      110.8408241, 32.5753119, 472.026,
      110.8409097, 32.5752587, 472.075,
      110.8409959, 32.5752048, 472.143,
      110.8410466, 32.575173, 472.091,
      110.8411654, 32.5750987, 472.048,
      110.8412501, 32.5750454, 472.097,
      110.8413357, 32.5749919, 472.128,
      110.8414215, 32.5749383, 472.114,
      110.8415074, 32.574885, 472.157,
      110.8415929, 32.5748322, 472.103,
      110.8416788, 32.5747791, 472.078,
      110.841764, 32.5747263, 472.036,
      110.8418519, 32.5746728, 471.982,
      110.8419383, 32.5746199, 472.048,
      110.8420239, 32.5745658, 472.121,
      110.8421089, 32.5745128, 472.147,
      110.8421962, 32.5744587, 472.126,
      110.8422818, 32.5744051, 472.12,
      110.8423661, 32.5743526, 472.066,
      110.8424507, 32.5742991, 471.996,
      110.8425365, 32.5742448, 472.019,
      110.8426243, 32.5741905, 472.138,
      110.8426934, 32.5741475, 472.202,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8427447, 32.574115, 472.209,
      110.8428304, 32.5740619, 472.121,
      110.8430345, 32.5739343, 472.011,
      110.8432055, 32.5738273, 472.108,
      110.8433606, 32.573731, 472.131,
      110.8435673, 32.5736018, 472.105,
      110.8437728, 32.5734746, 472.051,
      110.8439615, 32.5733577, 472.03,
      110.8441848, 32.5732188, 472.068,
      110.844392, 32.5730899, 472.121,
      110.8444936, 32.5730265, 472.103,
      110.8446831, 32.5729079, 472.066,
      110.844892, 32.5727805, 472.177,
      110.8451131, 32.5726418, 472.156,
      110.8452494, 32.5725569, 471.988,
      110.8453726, 32.5724744, 471.854,
      110.8454566, 32.5724016, 471.78,
      110.8455475, 32.5723255, 472.11,
      110.8456086, 32.5722749, 472.196,
      110.8456834, 32.5722124, 472.132,
      110.8457746, 32.5721369, 472.081,
      110.8458507, 32.5720733, 472.132,
      110.8459271, 32.5720105, 472.125,
      110.8459878, 32.57196, 472.076,
      110.8460785, 32.5718839, 472.083,
      110.846155, 32.5718207, 472.079,
      110.8462309, 32.5717584, 472.047,
      110.8463057, 32.5716958, 472.054,
      110.8463817, 32.5716325, 472.094,
      110.8464578, 32.5715691, 472.114,
      110.8465339, 32.5715055, 472.124,
      110.8466099, 32.5714426, 472.106,
      110.846686, 32.5713788, 472.053,
      110.8467623, 32.5713159, 472.038,
      110.8468398, 32.5712531, 472.176,
      110.8469163, 32.5711904, 472.168,
      110.8469924, 32.5711274, 472.059,
      110.8470682, 32.5710657, 472.029,
      110.847144, 32.5710025, 472.036,
      110.8472192, 32.5709394, 472.063,
      110.847294, 32.5708762, 472.046,
      110.8473691, 32.5708132, 472.048,
      110.8474456, 32.5707494, 472.086,
      110.8475072, 32.5706994, 472.118,
      110.8476001, 32.570624, 472.124,
      110.8476759, 32.5705606, 472.108,
      110.8477513, 32.5704978, 472.066,
      110.8478251, 32.5704351, 472.124,
      110.8479025, 32.5703715, 472.114,
      110.8479635, 32.5703212, 472.046,
      110.848055, 32.5702456, 472.04,
      110.8481307, 32.5701827, 471.965,
      110.8482062, 32.5701184, 471.977,
      110.8482827, 32.570055, 472.058,
      110.848358, 32.5699921, 472.064,
      110.8484331, 32.5699299, 472.104,
      110.84851, 32.5698669, 472.111,
      110.8485871, 32.569805, 472.123,
      110.8486659, 32.5697406, 472.279,
      110.8487426, 32.5696769, 472.32,
      110.8488174, 32.569613, 472.161,
      110.8488918, 32.5695482, 472.052,
      110.8489671, 32.5694865, 471.984,
      110.8490417, 32.5694245, 471.981,
      110.8491179, 32.5693621, 471.998,
      110.8491946, 32.5692988, 472.029,
      110.8492707, 32.5692349, 472.094,
      110.8493468, 32.5691723, 472.117,
      110.8494218, 32.5691099, 472.039,
      110.8494977, 32.569048, 471.992,
      110.8495722, 32.5689853, 472.013,
      110.8496491, 32.5689214, 472.063,
      110.8497253, 32.5688588, 472.081,
      110.849802, 32.5687955, 472.141,
      110.8498782, 32.5687332, 472.087,
      110.8499551, 32.5686699, 472.029,
      110.8500302, 32.5686065, 472.034,
      110.8501065, 32.5685433, 472.119,
      110.8501819, 32.5684807, 472.152,
      110.8502575, 32.5684181, 472.131,
      110.8503346, 32.5683548, 472.067,
      110.8504095, 32.5682916, 472.071,
      110.8504847, 32.568228, 471.975,
      110.8505599, 32.5681656, 471.929,
      110.8506355, 32.5681037, 471.99,
      110.8507049, 32.5680463, 472.348,
      110.8507544, 32.5680061, 472.443,
      110.8507896, 32.5679766, 472.266,
      110.8508146, 32.5679559, 472.04,
      110.850831, 32.5679419, 471.997,
      110.850836, 32.5679378, 472.001,
      110.8508422, 32.5679146, 472.051,
      110.8508201, 32.5678907, 472.061,
      110.8507878, 32.5678415, 471.994,
      110.8507462, 32.5677696, 471.986,
      110.8507008, 32.5676923, 472.125,
      110.8506551, 32.5676142, 472.164,
      110.8506068, 32.5675355, 472.144,
      110.8505581, 32.5674557, 472.186,
      110.8505098, 32.5673757, 472.181,
      110.8504706, 32.5673128, 472.175,
      110.8504706, 32.5673128, 472.175,
      110.8504706, 32.5673128, 472.175,
      110.8504198, 32.5672321, 472.124,
      110.850322, 32.5670739, 472.028,
      110.850214, 32.5668972, 472.134,
      110.8501662, 32.5668186, 472.235,
      110.8501184, 32.5667382, 472.322,
      110.8500697, 32.566659, 472.239,
      110.8500197, 32.5665787, 472.16,
      110.8499805, 32.5665159, 472.123,
      110.8499215, 32.5664197, 471.935,
      110.849873, 32.5663395, 471.928,
      110.8498238, 32.566259, 471.965,
      110.8497752, 32.5661784, 472.016,
      110.8497268, 32.5660996, 472.049,
      110.8496771, 32.566019, 472.046,
      110.8496288, 32.5659399, 472.074,
      110.8495801, 32.5658601, 472.084,
      110.8495311, 32.5657801, 471.958,
      110.8494818, 32.5657003, 471.982,
      110.8494333, 32.5656204, 472.008,
      110.8493834, 32.5655401, 472.019,
      110.8493338, 32.5654596, 472.063,
      110.8492861, 32.5653804, 472.079,
      110.8492372, 32.5653001, 472.043,
      110.8491884, 32.5652209, 472.07,
      110.8491385, 32.5651412, 472.173,
      110.8490891, 32.565061, 472.286,
      110.8490406, 32.5649811, 472.281,
      110.8489916, 32.5649019, 472.232,
      110.848941, 32.5648211, 472.169,
      110.8488913, 32.5647405, 472.027,
      110.8488422, 32.5646601, 471.972,
      110.8487938, 32.564581, 471.983,
      110.8487447, 32.5645005, 471.958,
      110.8486956, 32.5644207, 471.998,
      110.848647, 32.5643411, 472.057,
      110.8485976, 32.5642624, 472.113,
      110.8485578, 32.5641983, 472.158,
      110.848518, 32.5641338, 472.181,
      110.8484495, 32.5640227, 472.114,
      110.8484008, 32.5639426, 472.031,
      110.8483525, 32.5638621, 471.986,
      110.8483043, 32.5637826, 472.031,
      110.8482543, 32.5637028, 472.025,
      110.8482051, 32.5636243, 472.028,
      110.8481556, 32.5635434, 472.14,
      110.8481064, 32.5634638, 472.141,
      110.8480566, 32.5633837, 472.073,
      110.8480076, 32.5633036, 472.048,
      110.847959, 32.5632232, 472.078,
      110.8479094, 32.5631436, 472.106,
      110.8478596, 32.5630641, 472.076,
      110.8478104, 32.5629837, 472.075,
      110.8477622, 32.5629035, 472.078,
      110.8477151, 32.5628239, 472.135,
      110.8476673, 32.562745, 472.189,
      110.8476179, 32.5626651, 472.149,
      110.8475717, 32.5625914, 472.162,
      110.8475393, 32.5625417, 471.925,
      110.8475107, 32.5624983, 471.962,
      110.8474934, 32.5624692, 472.039,
      110.8474816, 32.5624478, 472.048,
      110.8474639, 32.562428, 472.027,
      110.8474205, 32.562414, 472,
      110.8473516, 32.5623855, 472.025,
      110.8472637, 32.5623427, 472.077,
      110.8471735, 32.5622992, 472.134,
      110.8470819, 32.5622562, 472.102,
      110.8469888, 32.5622127, 472.095,
      110.8468975, 32.5621688, 472.138,
      110.8468047, 32.5621247, 472.151,
      110.8467112, 32.5620801, 472.084,
      110.8466189, 32.5620359, 472.106,
      110.846526, 32.5619916, 472.088,
      110.8464318, 32.5619469, 472.052,
      110.8463398, 32.5619027, 472.127,
      110.8462473, 32.5618589, 472.132,
      110.8461538, 32.5618147, 472.06,
      110.8460592, 32.5617714, 472.098,
      110.8459663, 32.5617289, 472.233,
      110.8458728, 32.5616842, 472.121,
      110.8457797, 32.5616401, 472.021,
      110.845687, 32.5615942, 471.986,
      110.8455944, 32.5615498, 472.026,
      110.8455014, 32.561506, 472.047,
      110.8454093, 32.5614616, 472.05,
      110.8453155, 32.5614174, 472.05,
      110.8452226, 32.5613742, 472.052,
      110.8451314, 32.5613298, 472.085,
      110.8450393, 32.5612842, 472.101,
      110.8449467, 32.5612399, 472.046,
      110.8448545, 32.5611965, 472.07,
      110.8447606, 32.5611521, 472.076,
      110.844668, 32.5611083, 472.049,
      110.844574, 32.5610644, 472.092,
      110.8444808, 32.5610206, 472.129,
      110.8443877, 32.5609758, 472.209,
      110.8442956, 32.5609306, 472.17,
      110.8442036, 32.560886, 472.11,
      110.8441108, 32.5608424, 472.121,
      110.8440187, 32.5607996, 472.181,
      110.8439308, 32.5607571, 472.081,
      110.8438344, 32.5607123, 471.945,
      110.8437404, 32.5606653, 472.097,
      110.8436506, 32.5606225, 472.092,
      110.8435576, 32.560579, 472.017,
      110.8434656, 32.5605361, 472.039,
      110.8433725, 32.5604923, 472.004,
      110.8432801, 32.5604491, 472.001,
      110.8431868, 32.5604056, 472.016,
      110.8430931, 32.5603611, 471.998,
      110.8429993, 32.5603165, 472.044,
      110.8428887, 32.5602634, 472.095,
      110.8428142, 32.5602278, 472.084,
      110.8427221, 32.5601838, 472.077,
      110.8426284, 32.5601388, 472.1,
      110.842536, 32.5600947, 472.12,
      110.8424428, 32.5600508, 472.123,
      110.8423501, 32.5600067, 472.112,
      110.8422581, 32.5599631, 472.119,
      110.8421648, 32.5599184, 472.118,
      110.8420732, 32.5598737, 472.081,
      110.8419807, 32.5598301, 472.134,
      110.8419266, 32.5598045, 472.17,
      110.8418585, 32.5597716, 472.146,
      110.8417876, 32.5597395, 471.959,
      110.841755, 32.5597247, 471.997,
      110.8417332, 32.5597137, 472.06,
      110.8417146, 32.5597021, 472.077,
      110.8417092, 32.5596762, 472.065,
      110.8416937, 32.5596275, 472.046,
      110.8416724, 32.559557, 472.035,
      110.8416394, 32.5594541, 472.131,
      110.8416171, 32.5593844, 472.193,
      110.8415883, 32.5592974, 472.156,
      110.8415596, 32.5592119, 472.156,
      110.8415311, 32.5591247, 472.104,
      110.8415021, 32.5590378, 472.038,
      110.8414733, 32.5589512, 472.039,
      110.8414445, 32.5588646, 472.129,
      110.8414158, 32.558777, 472.108,
      110.8413875, 32.5586908, 472.071,
      110.8413584, 32.558604, 472.052,
      110.8413303, 32.5585185, 472.106,
      110.8413013, 32.5584316, 472.129,
      110.8412716, 32.5583444, 472.142,
      110.8412428, 32.5582591, 472.151,
      110.8412156, 32.558173, 472.089,
      110.8411882, 32.5580859, 472.025,
      110.8411605, 32.5580001, 472.015,
      110.8411316, 32.5579129, 472.038,
      110.8411035, 32.5578261, 472.093,
      110.8410756, 32.5577394, 472.141,
      110.8410474, 32.5576528, 472.139,
      110.8410201, 32.5575661, 472.236,
      110.8409914, 32.557479, 472.161,
      110.8409611, 32.5573914, 472.046,
      110.840932, 32.5573055, 472.094,
      110.8409031, 32.5572184, 472.059,
      110.8408756, 32.5571333, 472.101,
      110.8408469, 32.5570468, 472.106,
      110.8408178, 32.5569605, 472.146,
      110.8407902, 32.5568737, 472.198,
      110.8407612, 32.5567868, 472.148,
      110.8407321, 32.5566995, 472.042,
      110.8407038, 32.5566135, 472.118,
      110.8406754, 32.5565268, 472.081,
      110.8406475, 32.5564407, 472.095,
      110.8406189, 32.5563539, 471.987,
      110.8405913, 32.5562671, 471.958,
      110.8405629, 32.5561811, 472.026,
      110.8405349, 32.5560943, 472.025,
      110.8405073, 32.5560074, 472.105,
      110.8404784, 32.5559203, 471.963,
      110.84045, 32.5558335, 471.976,
      110.8404222, 32.555748, 472.066,
      110.8403933, 32.5556604, 472.105,
      110.840365, 32.5555741, 472.107,
      110.8403359, 32.5554861, 472.032,
      110.8403076, 32.5553996, 472.034,
      110.8402789, 32.5553135, 472.073,
      110.8402509, 32.5552257, 472.135,
      110.8402223, 32.555139, 472.145,
      110.8401941, 32.5550535, 472.074,
      110.8401668, 32.5549675, 472.036,
      110.8401379, 32.5548809, 472.024,
      110.8401035, 32.5547764, 472.127,
      110.8400838, 32.5547194, 472.285,
      110.8400654, 32.5546677, 472.205,
      110.8400523, 32.5546286, 472.012,
      110.8400392, 32.5545873, 471.952,
      110.8400175, 32.5545435, 471.977,
      110.8399777, 32.554478, 471.922,
      110.83993, 32.5543983, 472.027,
      110.8398844, 32.5543183, 472.131,
      110.8398368, 32.5542399, 472.219,
      110.8397866, 32.5541616, 472.133,
      110.8397372, 32.5540819, 472.105,
      110.839688, 32.5540015, 472.091,
      110.8396387, 32.553922, 472.08,
      110.8395881, 32.5538417, 472.03,
      110.8395395, 32.5537612, 472.054,
      110.8394885, 32.5536815, 472.037,
      110.8394405, 32.5536034, 472.074,
      110.839392, 32.5535242, 472.093,
      110.8393414, 32.5534433, 472.14,
      110.8392921, 32.553363, 472.147,
      110.8392429, 32.5532829, 472.085,
      110.839194, 32.5532045, 472.031,
      110.8391443, 32.5531239, 472.058,
      110.8390949, 32.5530441, 472.098,
      110.8390463, 32.5529649, 472.076,
      110.8389969, 32.5528849, 472.047,
      110.8389362, 32.5527891, 472.072,
      110.8388975, 32.5527249, 472.128,
      110.838849, 32.5526443, 472.097,
      110.8387991, 32.5525636, 472.059,
      110.8387514, 32.5524834, 472.033,
      110.8387016, 32.552403, 472.069,
      110.8386536, 32.5523242, 472.123,
      110.838604, 32.5522444, 472.105,
      110.8385547, 32.5521638, 472.15,
      110.8385067, 32.5520833, 472.086,
      110.8384588, 32.552004, 472.143,
      110.8384099, 32.5519244, 472.13,
      110.8383586, 32.551844, 472.084,
      110.838319, 32.5517817, 472.094,
      110.8382791, 32.5517181, 472.074,
      110.8382101, 32.5516065, 472.054,
      110.8381618, 32.5515265, 472.094,
      110.8381122, 32.5514471, 472.13,
      110.8380529, 32.5513505, 472.098,
      110.8380329, 32.5513183, 472.088,
      110.8379646, 32.5512071, 472.133,
      110.8379056, 32.551112, 472.164,
      110.8378665, 32.551048, 472.14,
      110.8378166, 32.5509678, 472.073,
      110.8377762, 32.550904, 472,
      110.8377067, 32.550792, 471.934,
      110.8376671, 32.5507284, 471.993,
      110.8376183, 32.5506505, 472.092,
      110.8375791, 32.5505872, 472.143,
      110.8375209, 32.550491, 472.128,
      110.8374913, 32.5504431, 472.095,
      110.8374407, 32.5503622, 471.915,
      110.837373, 32.5502506, 472.011,
      110.8373237, 32.5501708, 472.132,
      110.8373039, 32.5501383, 472.19,
      110.8372237, 32.5500119, 472.028,
      110.8371746, 32.5499314, 471.945,
      110.8371444, 32.5498831, 471.956,
      110.8370747, 32.5497726, 471.991,
      110.8370273, 32.549694, 472.06,
      110.8369974, 32.5496434, 472.233,
      110.8369728, 32.549604, 472.245,
      110.836949, 32.5495654, 472.101,
      110.8369332, 32.5495368, 472.068,
      110.8369217, 32.5495182, 472.121,
      110.8369159, 32.5494987, 472.105,
      110.8369371, 32.5494653, 472.055,
      110.8369605, 32.5494142, 472.08,
      110.8369844, 32.5493414, 472.122,
      110.8370088, 32.5492545, 472.24,
      110.8370356, 32.5491672, 472.277,
      110.8370524, 32.5491147, 472.197,
      110.837081, 32.5490277, 472.116,
      110.8371198, 32.5489065, 472.136,
      110.8371421, 32.5488368, 472.133,
      110.8371698, 32.5487487, 472.078,
      110.8371928, 32.5486793, 472.089,
      110.8372314, 32.5485574, 472.068,
      110.8372424, 32.5485223, 472.059,
      110.8372709, 32.5484356, 472.08,
      110.8373118, 32.5483152, 471.999,
      110.8373453, 32.548211, 472.078,
      110.8373616, 32.5481599, 472.096,
      110.8373901, 32.5480733, 472.051,
      110.8374176, 32.5479869, 472.028,
      110.8374517, 32.5478824, 472.058,
      110.8374799, 32.5477953, 472.094,
      110.8375031, 32.5477264, 472.169,
      110.8375431, 32.5476064, 472.241,
      110.8375604, 32.5475538, 472.202,
      110.8375945, 32.547449, 472.008,
      110.8376174, 32.5473785, 471.983,
      110.8376563, 32.547257, 471.982,
      110.8376726, 32.5472053, 472.008,
      110.8377125, 32.547084, 472.056,
      110.8377341, 32.5470139, 472.118,
      110.8377558, 32.5469442, 472.166,
      110.8377895, 32.5468406, 472.136,
      110.837822, 32.5467355, 472.09,
      110.837845, 32.5466653, 472.074,
      110.8378792, 32.5465625, 472.096,
      110.8379075, 32.5464763, 472.087,
      110.8379356, 32.5463895, 472.085,
      110.8379525, 32.5463371, 472.08,
      110.8379864, 32.5462329, 472.08,
      110.8380035, 32.5461809, 472.089,
      110.8380317, 32.546095, 472.113,
      110.8380317, 32.546095, 472.113,
      110.83806, 32.5460077, 472.113,
      110.8381046, 32.5458691, 472.145,
      110.8381564, 32.5457136, 472.075,
      110.8381958, 32.5455934, 472.131,
      110.8382244, 32.5455052, 472.089,
      110.8382457, 32.5454363, 472.108,
      110.8382735, 32.5453477, 472.03,
      110.8383069, 32.5452432, 472.052,
      110.8383301, 32.5451743, 472.084,
      110.8383638, 32.545071, 472.138,
      110.8383867, 32.545003, 472.155,
      110.8384204, 32.5448977, 472.127,
      110.8384474, 32.5448114, 472.115,
      110.8384753, 32.5447233, 472.076,
      110.8385026, 32.5446352, 472.026,
      110.8385294, 32.5445487, 472.028,
      110.838558, 32.5444625, 472.066,
      110.8385865, 32.5443761, 472.087,
      110.8386137, 32.5442927, 472.155,
      110.838637, 32.5442274, 472.228,
      110.8386491, 32.5441875, 472.044,
      110.8386599, 32.5441454, 471.856,
      110.8386703, 32.5441135, 471.913,
      110.8386754, 32.544079, 472.027,
      110.8386609, 32.54403, 471.943,
      110.8386523, 32.5439535, 471.988,
      110.8386476, 32.5438637, 472.17,
      110.8386392, 32.5437746, 472.283,
      110.8386297, 32.5436869, 472.218,
      110.83862, 32.543597, 472.137,
      110.8386124, 32.5435247, 472.132,
      110.8386028, 32.543435, 472.086,
      110.8385921, 32.5433268, 472.039,
      110.8385827, 32.5432369, 472.039,
      110.8385733, 32.5431469, 472.048,
      110.8385642, 32.5430583, 472.07,
      110.8385552, 32.5429693, 472.099,
      110.8385462, 32.5428792, 472.103,
      110.8385374, 32.5427901, 472.087,
      110.8385286, 32.5427002, 472.132,
      110.8385201, 32.542611, 472.131,
      110.8385099, 32.5425204, 471.98,
      110.8385009, 32.5424317, 471.996,
      110.8384922, 32.542343, 472.038,
      110.8384837, 32.5422698, 472.037,
      110.8384731, 32.5421624, 472.108,
      110.8384647, 32.5420731, 472.107,
      110.8384552, 32.5419833, 472.037,
      110.8384481, 32.5419116, 472.008,
      110.8384371, 32.5418049, 472.028,
      110.8384299, 32.5417328, 472.085,
      110.8384184, 32.5416253, 472.128,
      110.8384098, 32.5415352, 472.101,
      110.8384001, 32.5414458, 472.055,
      110.8383931, 32.541374, 472.06,
      110.8383818, 32.5412669, 472.094,
      110.838372, 32.5411771, 472.107,
      110.8383649, 32.5411064, 472.11,
      110.8383534, 32.540998, 472.112,
      110.8383446, 32.5409079, 472.086,
      110.8383356, 32.5408182, 472.078,
      110.8383264, 32.5407289, 472.084,
      110.8383162, 32.5406391, 472.063,
      110.8383076, 32.5405495, 472.111,
      110.8382978, 32.5404605, 472.109,
      110.8382889, 32.5403714, 472.09,
      110.8382799, 32.5402814, 472.142,
      110.8382728, 32.5402093, 472.1,
      110.8382617, 32.5401016, 472.054,
      110.8382527, 32.5400119, 472.084,
      110.8382454, 32.5399402, 472.044,
      110.8382342, 32.5398319, 472.042,
      110.8382263, 32.5397604, 472.095,
      110.8382156, 32.5396534, 472.092,
      110.8382062, 32.5395641, 472.09,
      110.8381978, 32.5394757, 472.091,
      110.8381883, 32.5393855, 472.081,
      110.8381828, 32.5393322, 472.085,
      110.8381689, 32.5392054, 472.086,
      110.8381595, 32.5391162, 472.073,
      110.8381512, 32.5390271, 472.042,
      110.8381424, 32.5389377, 472.084,
      110.8381325, 32.538849, 472.135,
      110.8381227, 32.5387586, 472.156,
      110.8381136, 32.5386686, 472.164,
      110.8381042, 32.5385784, 472.115,
      110.8380942, 32.5384886, 472.072,
      110.8380843, 32.5383991, 472.066,
      110.838075, 32.5383096, 472.062,
      110.8380655, 32.5382206, 472.03,
      110.8380571, 32.5381308, 472.063,
      110.8380478, 32.5380409, 472.059,
      110.8380379, 32.5379512, 472.055,
      110.8380287, 32.5378615, 472.052,
      110.8380212, 32.5377901, 472.045,
      110.8380105, 32.537684, 472.075,
      110.8380007, 32.5375942, 472.186,
      110.8379909, 32.5375042, 472.176,
      110.8379821, 32.5374139, 472.107,
      110.8379735, 32.537325, 472.086,
      110.8379651, 32.5372356, 472.009,
      110.8379559, 32.537146, 472.092,
      110.8379462, 32.5370568, 472.16,
      110.8379371, 32.5369672, 472.114,
      110.8379279, 32.5368774, 472.066,
      110.8379185, 32.5367887, 472.075,
      110.837909, 32.5366985, 472.057,
      110.8378993, 32.536608, 472.051,
      110.8378904, 32.5365184, 472.066,
      110.8378816, 32.5364289, 472.043,
      110.8378717, 32.5363399, 472.045,
      110.8378627, 32.5362506, 472.063,
      110.8378536, 32.5361604, 472.097,
      110.8378447, 32.5360715, 472.131,
      110.8378352, 32.5359811, 472.113,
      110.8378257, 32.5358911, 472.066,
      110.8378165, 32.5358012, 472.054,
      110.8378074, 32.5357119, 472.076,
      110.8377983, 32.5356224, 472.077,
      110.8377892, 32.5355326, 472.076,
      110.8377799, 32.5354427, 472.081,
      110.8377706, 32.5353539, 472.103,
      110.8377611, 32.5352648, 471.999,
      110.8377521, 32.5351745, 472.008,
      110.8377432, 32.5350846, 472.095,
      110.8377341, 32.534995, 472.165,
      110.8377245, 32.5349063, 472.13,
      110.8377155, 32.5348169, 472.094,
      110.8377058, 32.5347263, 472.069,
      110.8376968, 32.5346365, 472.068,
      110.8376873, 32.5345469, 472.092,
      110.8376781, 32.5344577, 472.054,
      110.8376687, 32.534368, 472.06,
      110.8376593, 32.5342781, 472.1,
      110.8376505, 32.5341893, 472.143,
      110.8376407, 32.5341, 472.197,
      110.8376319, 32.5340098, 472.123,
      110.8376223, 32.5339198, 472.128,
      110.8376127, 32.5338298, 472.053,
      110.8376041, 32.53374, 472.082,
      110.8375953, 32.533651, 472.063,
      110.8375855, 32.5335606, 472.054,
      110.837577, 32.5334721, 472.021,
      110.8375671, 32.5333819, 471.991,
      110.837557, 32.5332919, 472.017,
      110.8375478, 32.5332034, 472.07,
      110.8375391, 32.5331132, 472.099,
      110.8375303, 32.5330238, 472.123,
      110.8375214, 32.5329345, 472.093,
      110.8375127, 32.5328521, 472.124,
      110.8375074, 32.5327981, 471.928,
      110.8375011, 32.5327471, 472.032,
      110.8374977, 32.5327146, 472.115,
      110.8374952, 32.5326935, 472.13,
      110.8374907, 32.5326758, 472.115,
      110.837467, 32.5326637, 472.063,
      110.8374232, 32.5326378, 472.034,
      110.8373589, 32.5325958, 472.054,
      110.8372776, 32.5325439, 472.117,
      110.837194, 32.5324914, 472.198,
      110.8371109, 32.532438, 472.195,
      110.8370271, 32.5323829, 472.121,
      110.8369424, 32.5323278, 472.059,
      110.8368575, 32.532273, 472.07,
      110.8367733, 32.5322176, 472.089,
      110.8366881, 32.5321627, 472.095,
      110.8366039, 32.5321074, 472.096,
      110.8365194, 32.5320523, 472.102,
      110.8364356, 32.5319976, 472.101,
      110.8363511, 32.5319423, 472.099,
      110.8362669, 32.5318872, 472.123,
      110.8361825, 32.5318316, 472.137,
      110.8360986, 32.5317769, 472.121,
      110.8360132, 32.5317216, 472.131,
      110.8359285, 32.5316666, 472.08,
      110.8358453, 32.5316116, 472.05,
      110.8357603, 32.5315559, 472.015,
      110.8356767, 32.5315013, 472.017,
      110.8355918, 32.5314457, 472.069,
      110.8355082, 32.5313908, 472.108,
      110.8354234, 32.5313361, 472.111,
      110.8353397, 32.5312818, 472.148,
      110.8352552, 32.5312266, 472.112,
      110.8351698, 32.5311715, 472.071,
      110.8350848, 32.5311166, 472.035,
      110.8350014, 32.5310615, 472.07,
      110.8349166, 32.5310065, 472.064,
      110.8348319, 32.5309511, 472.056,
      110.8347483, 32.5308964, 472.08,
      110.8346634, 32.5308411, 472.102,
      110.8345803, 32.5307872, 472.119,
      110.8344948, 32.5307314, 472.089,
      110.8344114, 32.5306769, 472.122,
      110.8343261, 32.5306211, 472.103,
      110.8342418, 32.5305663, 472.103,
      110.8341575, 32.5305111, 472.074,
      110.8340732, 32.5304564, 472.08,
      110.8339895, 32.5304013, 472.097,
      110.8339046, 32.5303463, 472.093,
      110.8338197, 32.5302911, 472.095,
      110.8337359, 32.5302368, 472.069,
      110.8336504, 32.5301812, 472.089,
      110.8335666, 32.5301265, 472.1,
      110.8334821, 32.5300711, 472.089,
      110.8333972, 32.5300163, 472.045,
      110.8333137, 32.5299619, 472.036,
      110.8332287, 32.5299064, 472.037,
      110.8331445, 32.5298513, 472.087,
      110.8330599, 32.5297963, 472.098,
      110.8329764, 32.5297414, 472.097,
      110.832892, 32.5296867, 472.055,
      110.8328066, 32.5296312, 472.059,
      110.8327218, 32.529576, 472.068,
      110.8326375, 32.5295211, 472.052,
      110.8325701, 32.5294773, 472.068,
      110.8324692, 32.5294115, 472.109,
      110.8323854, 32.5293557, 472.133,
      110.8323018, 32.5293008, 472.14,
      110.8322167, 32.5292457, 472.118,
      110.8321334, 32.529192, 472.12,
      110.8320689, 32.5291494, 471.943,
      110.8320174, 32.5291155, 471.924,
      110.8319747, 32.5290885, 472.102,
      110.8319407, 32.5290663, 472.14,
      110.8319116, 32.5290392, 472.103,
      110.8318802, 32.5289898, 472.052,
      110.8318266, 32.5289248, 472.04,
      110.8317638, 32.5288542, 472.19,
      110.8317013, 32.5287819, 472.247,
      110.8316379, 32.5287096, 472.159,
      110.8315756, 32.5286373, 472.113,
      110.8315118, 32.528564, 472.068,
      110.8314497, 32.5284917, 472.076,
      110.8313858, 32.5284182, 472.058,
      110.8313231, 32.5283458, 472.067,
      110.8312598, 32.5282726, 472.06,
      110.8312045, 32.528209, 472.088,
      110.8311674, 32.5281657, 471.861,
      110.8311311, 32.5281232, 472.017,
      110.8311097, 32.5280978, 472.123,
      110.831096, 32.5280814, 472.12,
      110.8310875, 32.5280727, 472.1,
      110.8310829, 32.528068, 470.964,
      110.8310809, 32.528064, 467.87,
      110.8310806, 32.5280633, 464.799,
      110.8310791, 32.5280613, 461.782,
      110.8310776, 32.5280608, 458.901,
      110.8310792, 32.5280615, 455.982,
      110.8310793, 32.528062, 452.966,
      110.8310776, 32.5280606, 450.026,
      110.8310779, 32.5280602, 447.163,
      110.8310795, 32.5280605, 444.27,
      110.8310804, 32.5280626, 440.774,
      110.8310808, 32.528063, 438.351,
      110.8310799, 32.5280625, 435.613,
      110.8310781, 32.5280616, 432.941,
      110.8310781, 32.5280605, 430.087,
      110.8310768, 32.5280606, 427.667,
      110.8310772, 32.5280599, 424.16,
      110.8310778, 32.5280598, 421.191,
      110.8310777, 32.5280617, 418.199,
      110.8310769, 32.5280626, 415.097,
      110.8310776, 32.5280615, 412.142,
      110.8310811, 32.5280611, 409.285,
      110.8310761, 32.528062, 406.052,
      110.8310778, 32.5280615, 403.803,
      110.8310759, 32.5280621, 400.84,
      110.8310754, 32.5280626, 397.912,
      110.8310745, 32.5280609, 395.065,
      110.8310774, 32.5280604, 392.237,
      110.8310779, 32.5280635, 389.236,
      110.8310776, 32.5280644, 386.348,
      110.8310818, 32.528061, 383.706,
      110.8310809, 32.5280595, 381.149,
      110.8310771, 32.5280592, 378.371,
      110.8310752, 32.5280608, 375.373,
      110.8310752, 32.5280609, 372.333,
      110.8310771, 32.5280598, 369.314,
      110.8310786, 32.5280612, 366.31,
      110.8310788, 32.5280612, 363.231,
      110.8310752, 32.5280612, 360.328,
      110.8310745, 32.5280611, 357.454,
      110.8310761, 32.5280611, 354.528,
      110.8310771, 32.5280605, 351.499,
      110.8310773, 32.5280631, 348.449,
      110.8310775, 32.5280635, 345.482,
      110.8310802, 32.5280616, 342.805,
      110.8310801, 32.5280612, 340.095,
      110.8310802, 32.5280609, 337.362,
      110.831078, 32.5280622, 333.969,
      110.8310787, 32.5280635, 332.455,
      110.8310792, 32.5280645, 332.587,
      110.8310873, 32.5280588, 332.725,
      110.8311232, 32.5280374, 332.602,
      110.8311713, 32.5280085, 332.583,
      110.8312175, 32.5279799, 332.412,
      110.8312694, 32.5279489, 332.05,
      110.8313277, 32.5279212, 331.255,
      110.8313663, 32.5279085, 329.901,
      110.8314008, 32.5279016, 328.096,
      110.8314264, 32.5279002, 326.454,
      110.8314372, 32.5278993, 324.69,
      110.8314389, 32.5278982, 322.831,
      110.8314384, 32.5278981, 321.285,
      110.8314361, 32.5278993, 319.307,
      110.8314364, 32.5278996, 317.702,
      110.8314342, 32.5278996, 315.217,
      110.8314328, 32.5278992, 312.637,
      110.8314364, 32.5278991, 311.028,
      110.8314456, 32.5279009, 309.182,
      110.8314478, 32.5279017, 306.962,
      110.8314496, 32.527902, 305.411,
      110.8314594, 32.5279031, 302.993,
      110.8314661, 32.527904, 301.406,
      110.8314708, 32.5279054, 299.407,
      110.8314683, 32.527905, 296.993,
      110.8314664, 32.5279035, 295.338,
      110.8314663, 32.5279031, 293.283,
      110.8314663, 32.5279043, 291.245,
      110.8314683, 32.5279058, 289.299,
      110.831468, 32.5279061, 287.386,
      110.8314674, 32.5279053, 285.415,
      110.8314674, 32.5279051, 283.525,
      110.8314669, 32.5279042, 281.795,
      110.8314692, 32.5279053, 280.074,
      110.8314697, 32.5279062, 279.147,
      110.8314667, 32.5279059, 278.232,
      110.8314641, 32.5279041, 277.534,
      110.8314637, 32.5279038, 277.549,
      110.8314648, 32.5279046, 277.65,
      110.8314656, 32.5279049, 277.639,
      110.831466, 32.5279049, 277.579,
      110.8314646, 32.5279042, 277.55,
      110.831463, 32.5279041, 277.508,
      110.8314634, 32.5279038, 277.516,
      110.8314642, 32.5279038, 277.534,
      110.8314649, 32.5279039, 277.533,
      110.8314646, 32.5279042, 277.507,
      110.8314645, 32.5279048, 277.502,
      110.8314644, 32.5279048, 277.489,
      110.8314639, 32.5279044, 277.467,
      110.831463, 32.527904, 277.45,
      110.8314625, 32.527904, 277.446,
      110.8314634, 32.5279042, 277.476,
      110.8314648, 32.5279042, 277.501,
      110.8314652, 32.5279042, 277.532,
      110.8314658, 32.5279042, 277.556,
      110.8314659, 32.5279041, 277.554,
      110.8314657, 32.5279041, 277.541,
      110.8314651, 32.5279045, 277.51,
      110.8314648, 32.5279046, 277.498,
      110.8314653, 32.5279047, 277.469,
      110.8314653, 32.5279045, 277.108,
      110.8314639, 32.5279039, 276.729,
      110.8314608, 32.527903, 276.194,
      110.8314602, 32.5279025, 275.353,
      110.8314606, 32.5279025, 274.338,
      110.8314613, 32.5279033, 273.455,
      110.8314617, 32.5279038, 272.5,
      110.8314593, 32.5279029, 271.797,
      110.8314555, 32.5279019, 271.095,
      110.8314563, 32.5279014, 270.455,
      110.8314572, 32.5278993, 269.668,
      110.831457, 32.5278996, 269.216,
      110.8314583, 32.5279004, 268.873,
      110.8314585, 32.5279004, 268.845,
      110.8314574, 32.5279, 268.724,
      110.8314575, 32.5279003, 268.71,
      110.8314575, 32.5279003, 268.715,
      110.8314575, 32.5279003, 268.715,
      110.8314576, 32.5279003, 268.714,
      110.8314576, 32.5279003, 268.716,

    ];
    let ent = new Cesium.Entity({
      polyline: {
        positions: Cesium.Cartesian3.fromDegreesArrayHeights(a),
        width: 6, //线的宽度（像素），默认为1
        granularity: Cesium.Math.RADIANS_PER_DEGREE,
        material: Cesium.Color.BLUE, //线的颜色，默认为白色
      }
    });
    // window.viewer.entities.add(ent);


    let bb = [
      {
        "x": -1908042.6782558344,
        "y": 5029069.93419898,
        "z": 3416986.6502145003
      },
      {
        "x": -1908041.6978872735,
        "y": 5029074.548679047,
        "z": 3416980.6168172685
      },
      {
        "x": -1908040.683869293,
        "y": 5029079.100943972,
        "z": 3416974.709726306
      },
      {
        "x": -1908039.8559240696,
        "y": 5029082.211708556,
        "z": 3416970.4578661765
      },
      {
        "x": -1908039.4317601821,
        "y": 5029084.401857965,
        "z": 3416967.4058788414
      },
      {
        "x": -1908039.2355038614,
        "y": 5029086.054714846,
        "z": 3416965.1243318985
      },
      {
        "x": -1908039.1352283703,
        "y": 5029087.669436922,
        "z": 3416962.828592111
      },
      {
        "x": -1908040.7345661402,
        "y": 5029088.814916349,
        "z": 3416960.229742152
      },
      {
        "x": -1908044.292837243,
        "y": 5029091.365574615,
        "z": 3416954.610674012
      },
      {
        "x": -1908047.5076515877,
        "y": 5029094.916450586,
        "z": 3416947.602888925
      },
      {
        "x": -1908051.2985886426,
        "y": 5029099.11247203,
        "z": 3416939.4659831845
      },
      {
        "x": -1908054.4102362564,
        "y": 5029102.523736721,
        "z": 3416932.9700530833
      },
      {
        "x": -1908058.8405686878,
        "y": 5029107.505191626,
        "z": 3416923.1835274054
      },
      {
        "x": -1908061.8507827122,
        "y": 5029110.887273282,
        "z": 3416916.5154911685
      },
      {
        "x": -1908065.628677718,
        "y": 5029115.048876659,
        "z": 3416908.302390604
      },
      {
        "x": -1908070.1876792687,
        "y": 5029120.025380786,
        "z": 3416898.436780267
      },
      {
        "x": -1908152.850589499,
        "y": 5029209.965967937,
        "z": 3416721.012167747
      },
      {
        "x": -1908157.5019833331,
        "y": 5029214.9739504745,
        "z": 3416711.1468639136
      },
      {
        "x": -1908161.3442573538,
        "y": 5029219.146157883,
        "z": 3416702.9152323822
      },
      {
        "x": -1908165.9714150596,
        "y": 5029224.037301626,
        "z": 3416693.0930553195
      },
      {
        "x": -1908169.0422866617,
        "y": 5029227.367279119,
        "z": 3416686.539286038
      },
      {
        "x": -1908173.650761021,
        "y": 5029232.473802843,
        "z": 3416676.6705079884
      },
      {
        "x": -1908177.4544962968,
        "y": 5029236.623782776,
        "z": 3416668.5353257386
      },
      {
        "x": -1908180.8181907802,
        "y": 5029240.169736652,
        "z": 3416661.8874959555
      },
      {
        "x": -1908183.13331804,
        "y": 5029242.566426037,
        "z": 3416657.0265204185
      },
      {
        "x": -1913348.315963777,
        "y": 5028487.651508159,
        "z": 3414890.448467055
      },
      {
        "x": -1913830.9421665822,
        "y": 5028402.598376303,
        "z": 3414746.3393000388
      },
      {
        "x": -1913840.3386150377,
        "y": 5028400.861297963,
        "z": 3414743.4892952163
      },
      {
        "x": -1913849.8742514106,
        "y": 5028399.093921114,
        "z": 3414740.6265208987
      },
      {
        "x": -1914357.0155144343,
        "y": 5028354.526127619,
        "z": 3414523.532571401
      },
      {
        "x": -1914365.7059375686,
        "y": 5028354.707611081,
        "z": 3414518.4701237655
      },
      {
        "x": -1914374.6006704767,
        "y": 5028354.897855827,
        "z": 3414513.459358621
      },
      {
        "x": -1914381.597708636,
        "y": 5028355.039002466,
        "z": 3414509.475068477
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914395.4153136883,
        "y": 5028355.174434635,
        "z": 3414501.431342611
      },
      {
        "x": -1914416.0053208827,
        "y": 5028355.388684483,
        "z": 3414489.446699119
      },
      {
        "x": -1914433.3148543374,
        "y": 5028355.722300673,
        "z": 3414479.4987528306
      },
      {
        "x": -1914532.1756047898,
        "y": 5028356.923695094,
        "z": 3414422.6068692068
      },
      {
        "x": -1914553.1142789682,
        "y": 5028357.234552931,
        "z": 3414410.588373358
      },
      {
        "x": -1914563.3724474704,
        "y": 5028357.36313455,
        "z": 3414404.653294741
      },
      {
        "x": -1914582.511945817,
        "y": 5028357.619664139,
        "z": 3414393.5489632306
      },
      {
        "x": -1914603.5853791512,
        "y": 5028357.835425212,
        "z": 3414381.7018424612
      },
      {
        "x": -1914625.9300227468,
        "y": 5028358.16997328,
        "z": 3414368.727532915
      },
      {
        "x": -1914639.64536391,
        "y": 5028358.220361886,
        "z": 3414360.7022611806
      },
      {
        "x": -1914652.1702409333,
        "y": 5028358.601323352,
        "z": 3414352.919593241
      },
      {
        "x": -1914661.0667750428,
        "y": 5028359.798197735,
        "z": 3414346.075792719
      },
      {
        "x": -1914670.7601334676,
        "y": 5028361.26677676,
        "z": 3414339.141064189
      },
      {
        "x": -1914710.7716827255,
        "y": 5028366.169847675,
        "z": 3414309.708832923
      },
      {
        "x": -1914717.1570901843,
        "y": 5028366.920598899,
        "z": 3414304.962648676
      },
      {
        "x": -1914726.7360664797,
        "y": 5028368.141327254,
        "z": 3414297.853993739
      },
      {
        "x": -1914734.7914464362,
        "y": 5028369.108113166,
        "z": 3414291.9450662537
      },
      {
        "x": -1914742.7666536923,
        "y": 5028370.022661943,
        "z": 3414286.1051762616
      },
      {
        "x": -1914750.663396603,
        "y": 5028371.021406898,
        "z": 3414280.2582396376
      },
      {
        "x": -1914758.6902266212,
        "y": 5028372.045078808,
        "z": 3414274.363641355
      },
      {
        "x": -1914766.721963411,
        "y": 5028373.05521897,
        "z": 3414268.448925638
      },
      {
        "x": -1914774.754954433,
        "y": 5028374.068624475,
        "z": 3414262.5101298434
      }
    ]
    let c3List = []
    for (let i = 0; i < bb.length; i++) {
      let c3 = new Cesium.Cartesian3(bb[i].x, bb[i].y, bb[i].z);
      c3List.push(c3);
    }
    let ent1 = new Cesium.Entity({
      polyline: {
        positions: c3List,
        width: 6, //线的宽度（像素），默认为1
        granularity: Cesium.Math.RADIANS_PER_DEGREE,
        material: Cesium.Color.YELLOW, //线的颜色，默认为白色
      }
    });
    // window.viewer.entities.add(ent1);

    let bb1 = [
      {
        "x": -1913849.8742514106,
        "y": 5028399.093921114,
        "z": 3414740.6265208987
      },
      {
        "x": -1914357.0155144343,
        "y": 5028354.526127619,
        "z": 3414523.532571401
      },
      {
        "x": -1914365.7059375686,
        "y": 5028354.707611081,
        "z": 3414518.4701237655
      },
      {
        "x": -1914374.6006704767,
        "y": 5028354.897855827,
        "z": 3414513.459358621
      },
      {
        "x": -1914381.597708636,
        "y": 5028355.039002466,
        "z": 3414509.475068477
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914395.4153136883,
        "y": 5028355.174434635,
        "z": 3414501.431342611
      },
      {
        "x": -1914416.0053208827,
        "y": 5028355.388684483,
        "z": 3414489.446699119
      },
      {
        "x": -1914433.3148543374,
        "y": 5028355.722300673,
        "z": 3414479.4987528306
      },
      {
        "x": -1914448.9794787145,
        "y": 5028355.931754736,
        "z": 3414470.510972734
      },
      {
        "x": -1914469.8568887562,
        "y": 5028356.214285074,
        "z": 3414458.4219748173
      },
      {
        "x": -1914490.5781149352,
        "y": 5028356.403147231,
        "z": 3414446.504805089
      },
      {
        "x": -1914509.6160288104,
        "y": 5028356.60450106,
        "z": 3414435.5680241976
      },
      {
        "x": -1914532.1756047898,
        "y": 5028356.923695094,
        "z": 3414422.6068692068
      },
      {
        "x": -1914553.1142789682,
        "y": 5028357.234552931,
        "z": 3414410.588373358
      },
      {
        "x": -1914563.3724474704,
        "y": 5028357.36313455,
        "z": 3414404.653294741
      },
      {
        "x": -1914582.511945817,
        "y": 5028357.619664139,
        "z": 3414393.5489632306
      },
      {
        "x": -1914603.5853791512,
        "y": 5028357.835425212,
        "z": 3414381.7018424612
      },
      {
        "x": -1914625.9300227468,
        "y": 5028358.16997328,
        "z": 3414368.727532915
      },
      {
        "x": -1914639.64536391,
        "y": 5028358.220361886,
        "z": 3414360.7022611806
      },
      {
        "x": -1914652.1702409333,
        "y": 5028358.601323352,
        "z": 3414352.919593241
      },
      {
        "x": -1914661.0667750428,
        "y": 5028359.798197735,
        "z": 3414346.075792719
      },
      {
        "x": -1914670.7601334676,
        "y": 5028361.26677676,
        "z": 3414339.141064189
      },
      {
        "x": -1914677.2232397678,
        "y": 5028362.116134256,
        "z": 3414334.458228961
      },
      {
        "x": -1914685.0965369765,
        "y": 5028363.053532841,
        "z": 3414328.582447876
      },
      {
        "x": -1914694.6892351557,
        "y": 5028364.178483961,
        "z": 3414321.4986644867
      },
      {
        "x": -1914702.734488292,
        "y": 5028365.224346217,
        "z": 3414315.5819786643
      },
      {
        "x": -1914710.7716827255,
        "y": 5028366.169847675,
        "z": 3414309.708832923
      },
      {
        "x": -1914717.1570901843,
        "y": 5028366.920598899,
        "z": 3414304.962648676
      },
      {
        "x": -1914726.7360664797,
        "y": 5028368.141327254,
        "z": 3414297.853993739
      },
      {
        "x": -1914734.7914464362,
        "y": 5028369.108113166,
        "z": 3414291.9450662537
      },
      {
        "x": -1914742.7666536923,
        "y": 5028370.022661943,
        "z": 3414286.1051762616
      },
      {
        "x": -1914782.7559027348,
        "y": 5028375.024243647,
        "z": 3414256.6216794783
      },
      {
        "x": -1914790.774257021,
        "y": 5028375.999155493,
        "z": 3414250.630266946
      },
      {
        "x": -1914798.802440703,
        "y": 5028376.947074403,
        "z": 3414244.7434235406
      },
      {
        "x": -1914806.9797021337,
        "y": 5028377.969787184,
        "z": 3414238.948289571
      },
      {
        "x": -1914815.0232953979,
        "y": 5028378.905338419,
        "z": 3414233.083898848
      },
      {
        "x": -1914823.0078716113,
        "y": 5028379.791435122,
        "z": 3414227.137092428
      },
      {
        "x": -1914830.9621932346,
        "y": 5028380.6772205895,
        "z": 3414221.3543125577
      },
      {
        "x": -1914838.9594862638,
        "y": 5028381.675822089,
        "z": 3414215.451253844
      },
      {
        "x": -1914846.9079992278,
        "y": 5028382.704628805,
        "z": 3414209.5683041387
      },
      {
        "x": -1914854.81033995,
        "y": 5028383.717712661,
        "z": 3414203.6523169624
      },
      {
        "x": -1914862.740461532,
        "y": 5028384.724556447,
        "z": 3414197.76524674
      },
      {
        "x": -1914870.8212483672,
        "y": 5028385.757581602,
        "z": 3414191.8227825034
      },
      {
        "x": -1914877.2993674856,
        "y": 5028386.513852247,
        "z": 3414187.1668707076
      },
      {
        "x": -1914887.0563343924,
        "y": 5028387.620766262,
        "z": 3414180.1230027326
      },
      {
        "x": -1914895.0510046699,
        "y": 5028388.612283675,
        "z": 3414174.188840852
      },
      {
        "x": -1914902.9900272286,
        "y": 5028389.563197399,
        "z": 3414168.2967559174
      },
      {
        "x": -1914910.816501022,
        "y": 5028390.640743296,
        "z": 3414162.467846917
      },
      {
        "x": -1914918.9576504256,
        "y": 5028391.594615363,
        "z": 3414156.5182101647
      },
      {
        "x": -1914925.3595125703,
        "y": 5028392.308832813,
        "z": 3414151.780406373
      },
      {
        "x": -1914934.994289488,
        "y": 5028393.464111378,
        "z": 3414144.7113565006
      },
      {
        "x": -1914942.9518857838,
        "y": 5028394.384496803,
        "z": 3414138.7921402063
      },
      {
        "x": -1914950.9477738198,
        "y": 5028395.45817824,
        "z": 3414132.788906065
      },
      {
        "x": -1914959.0329990971,
        "y": 5028396.5025449945,
        "z": 3414126.906929566
      },
      {
        "x": -1914966.9797952208,
        "y": 5028397.500036043,
        "z": 3414121.0313058402
      },
      {
        "x": -1914974.9043662802,
        "y": 5028398.491914286,
        "z": 3414115.239405723
      },
      {
        "x": -1914982.9940138198,
        "y": 5028399.442259493,
        "z": 3414109.354965823
      },
      {
        "x": -1914991.079343957,
        "y": 5028400.328465717,
        "z": 3414103.576023557
      },
      {
        "x": -1914999.410184336,
        "y": 5028401.410727354,
        "z": 3414097.64093679
      },
      {
        "x": -1915007.5073623129,
        "y": 5028402.433533306,
        "z": 3414091.709362807
      },
      {
        "x": -1915015.3820589695,
        "y": 5028403.3734718915,
        "z": 3414085.651427014
      },
      {
        "x": -1915023.255774361,
        "y": 5028404.4163535265,
        "z": 3414079.5362858917
      },
      {
        "x": -1915031.1549061737,
        "y": 5028405.288465677,
        "z": 3414073.7329504057
      },
      {
        "x": -1915039.0184782343,
        "y": 5028406.251885736,
        "z": 3414067.9365627756
      },
      {
        "x": -1915047.0369716226,
        "y": 5028407.199878187,
        "z": 3414062.1135519384
      },
      {
        "x": -1915055.1226727087,
        "y": 5028408.192379809,
        "z": 3414056.213955729
      },
      {
        "x": -1915063.1786673632,
        "y": 5028409.265169935,
        "z": 3414050.2765795877
      },
      {
        "x": -1915071.1944450124,
        "y": 5028410.232333227,
        "z": 3414044.438093479
      },
      {
        "x": -1915079.0791437502,
        "y": 5028411.145542986,
        "z": 3414038.5639262223
      },
      {
        "x": -1915087.0415058574,
        "y": 5028412.025170609,
        "z": 3414032.753175139
      },
      {
        "x": -1915094.91839945,
        "y": 5028413.049762616,
        "z": 3414026.9042539583
      },
      {
        "x": -1915103.0401234557,
        "y": 5028414.083906985,
        "z": 3414020.958782251
      },
      {
        "x": -1915111.0631957478,
        "y": 5028415.043698159,
        "z": 3414015.117584344
      },
      {
        "x": -1915119.1576250333,
        "y": 5028416.058890284,
        "z": 3414009.233566149
      },
      {
        "x": -1915127.1527335872,
        "y": 5028416.945202534,
        "z": 3414003.3816410974
      },
      {
        "x": -1915135.229328499,
        "y": 5028417.860740176,
        "z": 3413997.4340940355
      },
      {
        "x": -1915143.1689764813,
        "y": 5028418.891620455,
        "z": 3413991.5111099468
      },
      {
        "x": -1915151.2336896397,
        "y": 5028419.934218102,
        "z": 3413985.6498793764
      },
      {
        "x": -1915159.1910732544,
        "y": 5028420.932452396,
        "z": 3413979.8167316155
      },
      {
        "x": -1915167.1498153622,
        "y": 5028421.8814550005,
        "z": 3413973.954511227
      },
      {
        "x": -1915175.2421810338,
        "y": 5028422.785488863,
        "z": 3413968.003714076
      },
      {
        "x": -1915227.7109259425,
        "y": 5028432.444642646,
        "z": 3413924.623171094
      },
      {
        "x": -1915206.5928298892,
        "y": 5028482.6182257775,
        "z": 3413863.100361328
      },
      {
        "x": -1915201.3422609062,
        "y": 5028494.637612902,
        "z": 3413848.2622946636
      },
      {
        "x": -1915195.6501332743,
        "y": 5028508.189094679,
        "z": 3413831.80380229
      },
      {
        "x": -1915193.1554126865,
        "y": 5028514.251434589,
        "z": 3413824.511682664
      },
      {
        "x": -1915190.6947267659,
        "y": 5028520.403158246,
        "z": 3413817.04378015
      },
      {
        "x": -1915188.0785494905,
        "y": 5028526.3841206655,
        "z": 3413809.596523458
      }
    ]

    let c3List1 = []
    for (let i = 0; i < bb1.length; i++) {
      let c3 = new Cesium.Cartesian3(bb1[i].x, bb1[i].y, bb1[i].z);
      c3List1.push(c3);
    }
    let ent11 = new Cesium.Entity({
      polyline: {
        positions: c3List1,
        width: 7, //线的宽度（像素），默认为1
        granularity: Cesium.Math.RADIANS_PER_DEGREE,
        material: Cesium.Color.RED, //线的颜色，默认为白色
      }
    });
    // window.viewer.entities.add(ent11);

    let c3List2 = [];
    let bb2 = [
      {
        "x": -1908074.7534268582,
        "y": 5028921.113457251,
        "z": 3417186.6907965504
      },
      {
        "x": -1908073.657939704,
        "y": 5028927.091372668,
        "z": 3417178.853076709
      },
      {
        "x": -1908071.9847021569,
        "y": 5028934.113524885,
        "z": 3417169.4418138396
      },
      {
        "x": -1908070.566489768,
        "y": 5028940.00833793,
        "z": 3417161.494343716
      },
      {
        "x": -1908069.4461873039,
        "y": 5028944.782975622,
        "z": 3417155.0246794885
      },
      {
        "x": -1908068.4042178132,
        "y": 5028949.4994459255,
        "z": 3417148.6169955074
      },
      {
        "x": -1908066.9438400404,
        "y": 5028956.606353424,
        "z": 3417139.05458361
      },
      {
        "x": -1908065.7019438301,
        "y": 5028962.46312929,
        "z": 3417131.111212788
      },
      {
        "x": -1908064.4181585067,
        "y": 5028968.31537266,
        "z": 3417123.2477836786
      },
      {
        "x": -1908063.0351834171,
        "y": 5028975.335250404,
        "z": 3417113.782545464
      },
      {
        "x": -1908061.77529086,
        "y": 5028981.276968211,
        "z": 3417105.780570685
      },
      {
        "x": -1908060.7260618664,
        "y": 5028986.027319031,
        "z": 3417099.4441667832
      },
      {
        "x": -1908059.4578538162,
        "y": 5028992.07947184,
        "z": 3417091.4931806796
      },
      {
        "x": -1908058.2054286813,
        "y": 5028997.987992722,
        "z": 3417083.565207137
      },
      {
        "x": -1908056.9037541596,
        "y": 5029003.846118425,
        "z": 3417075.688080439
      },
      {
        "x": -1908055.373615651,
        "y": 5029010.875207941,
        "z": 3417066.1423129067
      },
      {
        "x": -1908054.1040398178,
        "y": 5029016.791509215,
        "z": 3417058.1492812485
      },
      {
        "x": -1908053.1422829374,
        "y": 5029021.587243039,
        "z": 3417051.8203724306
      },
      {
        "x": -1908051.6701919334,
        "y": 5029028.716461167,
        "z": 3417042.4003124167
      },
      {
        "x": -1908050.6449468092,
        "y": 5029033.477210113,
        "z": 3417035.970257981
      },
      {
        "x": -1908049.3020177311,
        "y": 5029039.306099319,
        "z": 3417028.0268039014
      },
      {
        "x": -1908048.0218752702,
        "y": 5029045.194634162,
        "z": 3417020.0411083386
      },
      {
        "x": -1908046.7701951754,
        "y": 5029051.131740151,
        "z": 3417012.1040798705
      },
      {
        "x": -1908045.217778365,
        "y": 5029058.234600793,
        "z": 3417002.5052315663
      },
      {
        "x": -1908044.1708345916,
        "y": 5029062.964693547,
        "z": 3416996.065094393
      },
      {
        "x": -1908042.6782558344,
        "y": 5029069.93419898,
        "z": 3416986.6502145003
      },
      {
        "x": -1908041.6978872735,
        "y": 5029074.548679047,
        "z": 3416980.6168172685
      },
      {
        "x": -1908040.683869293,
        "y": 5029079.100943972,
        "z": 3416974.709726306
      },
      {
        "x": -1908039.8559240696,
        "y": 5029082.211708556,
        "z": 3416970.4578661765
      },
      {
        "x": -1908039.4317601821,
        "y": 5029084.401857965,
        "z": 3416967.4058788414
      },
      {
        "x": -1908039.2355038614,
        "y": 5029086.054714846,
        "z": 3416965.1243318985
      },
      {
        "x": -1908039.1352283703,
        "y": 5029087.669436922,
        "z": 3416962.828592111
      },
      {
        "x": -1908040.7345661402,
        "y": 5029088.814916349,
        "z": 3416960.229742152
      },
      {
        "x": -1908044.292837243,
        "y": 5029091.365574615,
        "z": 3416954.610674012
      },
      {
        "x": -1908047.5076515877,
        "y": 5029094.916450586,
        "z": 3416947.602888925
      },
      {
        "x": -1908051.2985886426,
        "y": 5029099.11247203,
        "z": 3416939.4659831845
      },
      {
        "x": -1908054.4102362564,
        "y": 5029102.523736721,
        "z": 3416932.9700530833
      },
      {
        "x": -1908058.8405686878,
        "y": 5029107.505191626,
        "z": 3416923.1835274054
      },
      {
        "x": -1908061.8507827122,
        "y": 5029110.887273282,
        "z": 3416916.5154911685
      },
      {
        "x": -1908065.628677718,
        "y": 5029115.048876659,
        "z": 3416908.302390604
      },
      {
        "x": -1908070.1876792687,
        "y": 5029120.025380786,
        "z": 3416898.436780267
      },
      {
        "x": -1908152.850589499,
        "y": 5029209.965967937,
        "z": 3416721.012167747
      },
      {
        "x": -1908157.5019833331,
        "y": 5029214.9739504745,
        "z": 3416711.1468639136
      },
      {
        "x": -1908161.3442573538,
        "y": 5029219.146157883,
        "z": 3416702.9152323822
      },
      {
        "x": -1908165.9714150596,
        "y": 5029224.037301626,
        "z": 3416693.0930553195
      },
      {
        "x": -1908169.0422866617,
        "y": 5029227.367279119,
        "z": 3416686.539286038
      },
      {
        "x": -1908173.650761021,
        "y": 5029232.473802843,
        "z": 3416676.6705079884
      },
      {
        "x": -1908177.4544962968,
        "y": 5029236.623782776,
        "z": 3416668.5353257386
      },
      {
        "x": -1908180.8181907802,
        "y": 5029240.169736652,
        "z": 3416661.8874959555
      },
      {
        "x": -1908183.13331804,
        "y": 5029242.566426037,
        "z": 3416657.0265204185
      },
      {
        "x": -1913348.315963777,
        "y": 5028487.651508159,
        "z": 3414890.448467055
      },
      {
        "x": -1913830.9421665822,
        "y": 5028402.598376303,
        "z": 3414746.3393000388
      },
      {
        "x": -1913840.3386150377,
        "y": 5028400.861297963,
        "z": 3414743.4892952163
      },
      {
        "x": -1913849.8742514106,
        "y": 5028399.093921114,
        "z": 3414740.6265208987
      },
      {
        "x": -1914357.0155144343,
        "y": 5028354.526127619,
        "z": 3414523.532571401
      },
      {
        "x": -1914365.7059375686,
        "y": 5028354.707611081,
        "z": 3414518.4701237655
      },
      {
        "x": -1914374.6006704767,
        "y": 5028354.897855827,
        "z": 3414513.459358621
      },
      {
        "x": -1914381.597708636,
        "y": 5028355.039002466,
        "z": 3414509.475068477
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914386.792431963,
        "y": 5028355.144056979,
        "z": 3414506.4414107497
      },
      {
        "x": -1914395.4153136883,
        "y": 5028355.174434635,
        "z": 3414501.431342611
      },
      {
        "x": -1914416.0053208827,
        "y": 5028355.388684483,
        "z": 3414489.446699119
      },
      {
        "x": -1914433.3148543374,
        "y": 5028355.722300673,
        "z": 3414479.4987528306
      },
      {
        "x": -1914448.9794787145,
        "y": 5028355.931754736,
        "z": 3414470.510972734
      },
      {
        "x": -1914469.8568887562,
        "y": 5028356.214285074,
        "z": 3414458.4219748173
      },
      {
        "x": -1914490.5781149352,
        "y": 5028356.403147231,
        "z": 3414446.504805089
      },
      {
        "x": -1914509.6160288104,
        "y": 5028356.60450106,
        "z": 3414435.5680241976
      },
      {
        "x": -1914532.1756047898,
        "y": 5028356.923695094,
        "z": 3414422.6068692068
      },
      {
        "x": -1914553.1142789682,
        "y": 5028357.234552931,
        "z": 3414410.588373358
      },
      {
        "x": -1914563.3724474704,
        "y": 5028357.36313455,
        "z": 3414404.653294741
      },
      {
        "x": -1914582.511945817,
        "y": 5028357.619664139,
        "z": 3414393.5489632306
      },
      {
        "x": -1914603.5853791512,
        "y": 5028357.835425212,
        "z": 3414381.7018424612
      },
      {
        "x": -1914625.9300227468,
        "y": 5028358.16997328,
        "z": 3414368.727532915
      },
      {
        "x": -1914639.64536391,
        "y": 5028358.220361886,
        "z": 3414360.7022611806
      },
      {
        "x": -1914652.1702409333,
        "y": 5028358.601323352,
        "z": 3414352.919593241
      },
      {
        "x": -1914661.0667750428,
        "y": 5028359.798197735,
        "z": 3414346.075792719
      },
      {
        "x": -1914670.7601334676,
        "y": 5028361.26677676,
        "z": 3414339.141064189
      },
      {
        "x": -1914677.2232397678,
        "y": 5028362.116134256,
        "z": 3414334.458228961
      },
      {
        "x": -1914685.0965369765,
        "y": 5028363.053532841,
        "z": 3414328.582447876
      },
      {
        "x": -1914694.6892351557,
        "y": 5028364.178483961,
        "z": 3414321.4986644867
      },
      {
        "x": -1914702.734488292,
        "y": 5028365.224346217,
        "z": 3414315.5819786643
      },
      {
        "x": -1914710.7716827255,
        "y": 5028366.169847675,
        "z": 3414309.708832923
      },
      {
        "x": -1914717.1570901843,
        "y": 5028366.920598899,
        "z": 3414304.962648676
      },
      {
        "x": -1914726.7360664797,
        "y": 5028368.141327254,
        "z": 3414297.853993739
      },
      {
        "x": -1914734.7914464362,
        "y": 5028369.108113166,
        "z": 3414291.9450662537
      },
      {
        "x": -1914742.7666536923,
        "y": 5028370.022661943,
        "z": 3414286.1051762616
      },
      {
        "x": -1914750.663396603,
        "y": 5028371.021406898,
        "z": 3414280.2582396376
      },
      {
        "x": -1914758.6902266212,
        "y": 5028372.045078808,
        "z": 3414274.363641355
      },
      {
        "x": -1914766.721963411,
        "y": 5028373.05521897,
        "z": 3414268.448925638
      },
      {
        "x": -1914774.754954433,
        "y": 5028374.068624475,
        "z": 3414262.5101298434
      },
      {
        "x": -1914782.7559027348,
        "y": 5028375.024243647,
        "z": 3414256.6216794783
      },
      {
        "x": -1914790.774257021,
        "y": 5028375.999155493,
        "z": 3414250.630266946
      },
      {
        "x": -1914798.802440703,
        "y": 5028376.947074403,
        "z": 3414244.7434235406
      },
      {
        "x": -1914806.9797021337,
        "y": 5028377.969787184,
        "z": 3414238.948289571
      },
      {
        "x": -1914815.0232953979,
        "y": 5028378.905338419,
        "z": 3414233.083898848
      },
      {
        "x": -1914823.0078716113,
        "y": 5028379.791435122,
        "z": 3414227.137092428
      },
      {
        "x": -1914830.9621932346,
        "y": 5028380.6772205895,
        "z": 3414221.3543125577
      },
      {
        "x": -1914838.9594862638,
        "y": 5028381.675822089,
        "z": 3414215.451253844
      },
      {
        "x": -1914846.9079992278,
        "y": 5028382.704628805,
        "z": 3414209.5683041387
      },
      {
        "x": -1914854.81033995,
        "y": 5028383.717712661,
        "z": 3414203.6523169624
      },
      {
        "x": -1914862.740461532,
        "y": 5028384.724556447,
        "z": 3414197.76524674
      },
      {
        "x": -1914870.8212483672,
        "y": 5028385.757581602,
        "z": 3414191.8227825034
      },
      {
        "x": -1914877.2993674856,
        "y": 5028386.513852247,
        "z": 3414187.1668707076
      },
      {
        "x": -1914887.0563343924,
        "y": 5028387.620766262,
        "z": 3414180.1230027326
      },
      {
        "x": -1914895.0510046699,
        "y": 5028388.612283675,
        "z": 3414174.188840852
      },
      {
        "x": -1914902.9900272286,
        "y": 5028389.563197399,
        "z": 3414168.2967559174
      },
      {
        "x": -1914910.816501022,
        "y": 5028390.640743296,
        "z": 3414162.467846917
      },
      {
        "x": -1914918.9576504256,
        "y": 5028391.594615363,
        "z": 3414156.5182101647
      },
      {
        "x": -1914925.3595125703,
        "y": 5028392.308832813,
        "z": 3414151.780406373
      },
      {
        "x": -1914934.994289488,
        "y": 5028393.464111378,
        "z": 3414144.7113565006
      },
      {
        "x": -1914942.9518857838,
        "y": 5028394.384496803,
        "z": 3414138.7921402063
      },
      {
        "x": -1914950.9477738198,
        "y": 5028395.45817824,
        "z": 3414132.788906065
      },
      {
        "x": -1914959.0329990971,
        "y": 5028396.5025449945,
        "z": 3414126.906929566
      },
      {
        "x": -1914966.9797952208,
        "y": 5028397.500036043,
        "z": 3414121.0313058402
      },
      {
        "x": -1914974.9043662802,
        "y": 5028398.491914286,
        "z": 3414115.239405723
      },
      {
        "x": -1914982.9940138198,
        "y": 5028399.442259493,
        "z": 3414109.354965823
      },
      {
        "x": -1914991.079343957,
        "y": 5028400.328465717,
        "z": 3414103.576023557
      },
      {
        "x": -1914999.410184336,
        "y": 5028401.410727354,
        "z": 3414097.64093679
      },
      {
        "x": -1915007.5073623129,
        "y": 5028402.433533306,
        "z": 3414091.709362807
      },
      {
        "x": -1915015.3820589695,
        "y": 5028403.3734718915,
        "z": 3414085.651427014
      },
      {
        "x": -1915023.255774361,
        "y": 5028404.4163535265,
        "z": 3414079.5362858917
      },
      {
        "x": -1915031.1549061737,
        "y": 5028405.288465677,
        "z": 3414073.7329504057
      },
      {
        "x": -1915039.0184782343,
        "y": 5028406.251885736,
        "z": 3414067.9365627756
      },
      {
        "x": -1915047.0369716226,
        "y": 5028407.199878187,
        "z": 3414062.1135519384
      },
      {
        "x": -1915055.1226727087,
        "y": 5028408.192379809,
        "z": 3414056.213955729
      },
      {
        "x": -1915063.1786673632,
        "y": 5028409.265169935,
        "z": 3414050.2765795877
      },
      {
        "x": -1915071.1944450124,
        "y": 5028410.232333227,
        "z": 3414044.438093479
      },
      {
        "x": -1915079.0791437502,
        "y": 5028411.145542986,
        "z": 3414038.5639262223
      },
      {
        "x": -1915087.0415058574,
        "y": 5028412.025170609,
        "z": 3414032.753175139
      },
      {
        "x": -1915094.91839945,
        "y": 5028413.049762616,
        "z": 3414026.9042539583
      },
      {
        "x": -1915103.0401234557,
        "y": 5028414.083906985,
        "z": 3414020.958782251
      },
      {
        "x": -1915111.0631957478,
        "y": 5028415.043698159,
        "z": 3414015.117584344
      },
      {
        "x": -1915119.1576250333,
        "y": 5028416.058890284,
        "z": 3414009.233566149
      },
      {
        "x": -1915127.1527335872,
        "y": 5028416.945202534,
        "z": 3414003.3816410974
      },
      {
        "x": -1915135.229328499,
        "y": 5028417.860740176,
        "z": 3413997.4340940355
      },
      {
        "x": -1915143.1689764813,
        "y": 5028418.891620455,
        "z": 3413991.5111099468
      },
      {
        "x": -1915151.2336896397,
        "y": 5028419.934218102,
        "z": 3413985.6498793764
      },
      {
        "x": -1915159.1910732544,
        "y": 5028420.932452396,
        "z": 3413979.8167316155
      },
      {
        "x": -1915167.1498153622,
        "y": 5028421.8814550005,
        "z": 3413973.954511227
      },
      {
        "x": -1915175.2421810338,
        "y": 5028422.785488863,
        "z": 3413968.003714076
      },
      {
        "x": -1915183.159744958,
        "y": 5028423.811016644,
        "z": 3413962.0988641568
      },
      {
        "x": -1915191.0821448967,
        "y": 5028424.770058525,
        "z": 3413956.1027936414
      },
      {
        "x": -1915198.9940471996,
        "y": 5028425.701511068,
        "z": 3413950.2457927605
      },
      {
        "x": -1915206.9625294027,
        "y": 5028426.67594857,
        "z": 3413944.4931189804
      },
      {
        "x": -1915214.3803543556,
        "y": 5028427.84046245,
        "z": 3413939.3209113777
      },
      {
        "x": -1915219.6073261264,
        "y": 5028428.503431524,
        "z": 3413935.614734848
      },
      {
        "x": -1915223.270334319,
        "y": 5028428.833224144,
        "z": 3413932.7622209936
      },
      {
        "x": -1915225.8364708696,
        "y": 5028428.974422303,
        "z": 3413930.705827191
      },
      {
        "x": -1915227.5603715933,
        "y": 5028429.1734194085,
        "z": 3413929.3741615904
      },
      {
        "x": -1915228.0875073443,
        "y": 5028429.238174968,
        "z": 3413928.9931056956
      },
      {
        "x": -1915229.139620829,
        "y": 5028430.364641169,
        "z": 3413926.851617649
      },
      {
        "x": -1915227.7109259425,
        "y": 5028432.444642646,
        "z": 3413924.623171094
      },
      {
        "x": -1915225.9015601864,
        "y": 5028436.216449083,
        "z": 3413919.988592261
      },
      {
        "x": -1915223.7760509409,
        "y": 5028441.612024619,
        "z": 3413913.2640970186
      },
      {
        "x": -1915221.4758729897,
        "y": 5028447.55165229,
        "z": 3413906.1140101757
      },
      {
        "x": -1915219.1363572215,
        "y": 5028453.467173924,
        "z": 3413898.835314653
      }
    ]

    for (let i = 0; i < bb2.length; i++) {
      let c3 = new Cesium.Cartesian3(bb2[i].x, bb2[i].y, bb2[i].z);
      c3List2.push(c3);
    }
    let ent1122 = new Cesium.Entity({
      polyline: {
        positions: c3List2,
        width: 7, //线的宽度（像素），默认为1
        granularity: Cesium.Math.RADIANS_PER_DEGREE,
        material: Cesium.Color.ORANGE, //线的颜色，默认为白色
      }
    });
    // window.viewer.entities.add(ent1122);





  },

  trans_location_data(device, uav_data) {
    if (!uav_data) {
      return uav_data
    }
    let uavInfo = uav_data.uavInfo
    let position = null
    let positions = []
    let orientation = null
    try {
      position = Cesium.Cartesian3.fromDegrees(uavInfo.longitude * 1, uavInfo.latitude * 1, uavInfo.altitude * 1)
      let yaw = Cesium.Math.toRadians(uavInfo.yaw + 90),
        pitch = Cesium.Math.toRadians(uavInfo.pitch),
        roll = Cesium.Math.toRadians(uavInfo.roll),
        hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll)
      orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr)

      if (uavInfo.longitude != 0 && uavInfo.latitude != 0) {
        positions.push(position)
      }
    } catch (e) {
      this.$message({
        type: 'warning',
        message: '定位失败'
      })
    }

    return {
      position,
      positions,
      orientation,
      device
    }
  },
  // 转换数据？
  trans_location_datas(device, uav_data) {
    if (!uav_data) {
      return uav_data
    }
    let uavInfo = uav_data.data.uavInfo
    // uavInfo.height += 10
    let position = Cesium.Cartesian3.fromDegrees(uavInfo.longitude * 1, uavInfo.latitude * 1, uavInfo.height * 1)
    let yaw = Cesium.Math.toRadians(uavInfo.yaw + 90),
      pitch = Cesium.Math.toRadians(uavInfo.pitch),
      roll = Cesium.Math.toRadians(uavInfo.roll),
      hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
      orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr)

    let positions = []
    if (uavInfo.longitude != 0 && uavInfo.latitude != 0) {
      positions.push(position)
    }
    return {
      position,
      positions,
      orientation,
      device
    }
  },
  // 飞控链路位置数据
  trans_mqtt_location_data(device, uav_data) {
    if (!uav_data || !uav_data.locationCoordinate3D) {
      return uav_data
    }
    // 获取航线
    let location = uav_data.locationCoordinate3D
    if (!location) {
      return
    }
    let position = Cesium.Cartesian3.fromDegrees(location.longitude * 1, location.latitude * 1, location.height * 1)
    let positions = []
    let attitude = uav_data.attitude
    let yaw = Cesium.Math.toRadians(attitude.yaw + 0),
      pitch = Cesium.Math.toRadians(attitude.pitch),
      roll = Cesium.Math.toRadians(attitude.roll),
      hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
      orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr)
    // console.log(this.uav.model, '飞控链路位置数据...模型');
    if (this.uav.model) {
      let model = this.uav.model
      // console.log(model, 'model');
      // console.log(model?.position, 'model');
      if (!model?.position) {
        return
      }
      const curPos = model.position.getValue(Cesium.JulianDate.now())
      if (curPos) {
        const curCart = new Cesium.Cartesian3(curPos.x, curPos.y, curPos.z)
        const afterCart = new Cesium.Cartesian3(position.x, position.y, position.z)
        if (curCart.equals(afterCart)) {
        } else {
          // 航线数据
          positions.push(position)
        }
      } else {
      }
    }
    return {
      position,
      positions,
      orientation,
      device
    }
  },
  Cartesian3_to_WGS84(point) {
    var cartesian33 = new Cesium.Cartesian3(point.x, point.y, point.z)
    var cartographic = Cesium.Cartographic.fromCartesian(cartesian33)
    var lat = Cesium.Math.toDegrees(cartographic.latitude)
    var lng = Cesium.Math.toDegrees(cartographic.longitude)
    var alt = cartographic.height
    return { lng: lng, lat: lat, alt: alt }
  },
  /**
   * 鹰视  航线 高度 加10
   * 创建无人机entity和航线entity
   * **/
  async show_location(device) {
    console.log(this.wsShow, 'this.wsShow')
    console.log(this.linevisible, 'this.linevisible')
    console.log(device, 'deviceeeeeeeeeee')
    if (!this.wsShow) {
      if (!this.linevisible) {
        this.linevisible = true
        viewer.trackedEntity = null
        let uav_data = await API.FCKERNEL.GetLastUavData({
          deviceHardId: device.deviceHardId
        })
        if (!uav_data || uav_data.latitude == '' || uav_data.longitude == '') {
          this.$el_message('暂未找到位置信息', () => { }, 'error')
          return uav_data
        }
        if (!data_source) {
          await this.uav_init_data_source()
        }
        let entities = data_source.entities
        let cesium_layer = this.g_cesium_layer()
        try {
          //由于3D地图悬浮于2D地图表面，需要增加无人机模型的海拔高度，不然会被3D地图遮挡
          // uav_data.uavInfo.altitude += 10
          uav_data.uavInfo.height
        } catch (e) { }
        // console.log('海拔高度增加10米', uav_data.uavInfo.altitude);
        let data = this.uav_trans_location_data(device, uav_data)
        let deviceHardId = device.deviceHardId
        let model = entities.getById(`fckernel_model_${deviceHardId}`)
        this.uav.online[deviceHardId] = data
        if (!model) {
          this.uav.online[deviceHardId].entity_id = `fckernel_model_${deviceHardId}`
          model = this.uav.model = cesium_layer.add_model(entities, this.uav.online[deviceHardId])
          //cartesian3转wgs84, 通过经纬度高度1000米创建新cartesian3，并设置相机飞入此位置
          var ellipsoid = viewer.scene.globe.ellipsoid
          var cartesian3 = model.position.getValue(Cesium.JulianDate.now())
          var cartogtraphic = ellipsoid.cartesianToCartographic(cartesian3)
          var lat = Cesium.Math.toDegrees(cartogtraphic.latitude)
          var lng = Cesium.Math.toDegrees(cartogtraphic.longitude)
          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(lng, lat, 3000), //model.position.getValue(Cesium.JulianDate.now()),
            // maximumHeight: 100,
            complete: () => {
              // debugger
              viewer.trackedEntity = model
              // model.viewFrom = new Cesium.Cartesian3(1000, 110000, 200155.0) // 偏移量--设置视角高度
              // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxx');
            }
          })
          // model.viewFrom = new Cesium.Cartesian3(0, 10000, 1000)
          this.uav.model = model
        }
        let polyline = entities.getById(`fckernel_polyline_${deviceHardId}`)
        if (!polyline) {
          let model = cesium_layer.add_polyline(entities, {
            ...data,
            entity_id: `fckernel_polyline_${deviceHardId}`,
            options: {
              show: true
            }
          })
          // console.log(model, 'xxxxxxxxxxxxxxxxxxxxxxxx')
          // model.polyline.positions = new Cesium.CallbackProperty();
        }
      } else {
        this.linevisible = false
        this.uav_remove_model(device)
      }
    } else {
      if (!this.linevisible) {
        this.linevisible = true
        let a = undefined;
        let b = (new Date()).getTime();
        console.log('originWSData', originWSData);
        console.log('b', b);
        if (!device.latestData) {
          while (!a) {
            a = _.find(originWSData, { 'deviceHardId': device.deviceHardId });
            // a.data = a.deviceData;
            device.latestData = a;
            if (device.latestData) {
               console.log('device.latestData', device.latestData);
                let a1 = device.latestData;
                let a3 = JSON.parse(a1.deviceData);
                // console.log('a3', a3);
                device.latestData = JSON.stringify(a3);
            }
            console.log('a', a);
            let c = (new Date()).getTime();
            // console.log('c-b', c-b);
            
            if (c - b > 10000) {
              a = true;
            }
          }
        }
        console.log('device.latestData', device.latestData);
        let uav_data = Parser.uav_data_parser(device.latestData)
        console.log('uav_data', uav_data);
        this.uav_show_airways(device, uav_data)
        console.log('this.uav.online', this.uav.online);
        console.log('device.deviceHardId', device.deviceHardId);
        let item = this.uav.online[device.deviceHardId];
        console.log('item', item);
        if (item) {
          let entity = viewer.entities.getById(item.entity_id)
          viewer.trackedEntity = entity
          // 如果是TD220，那么还有两个桨叶
          let low = viewer.entities.getById(item.entity_id + '_lower_rotar')
          let up = viewer.entities.getById(item.entity_id + '_upper_rotar')
          entity.show = true
          if (low) low.show = true
          if (up) up.show = true
        }
      } else {
        this.linevisible = false
        // this.uav_hide_location_all(device, uav_data)
        this.uav_hide_airways(device)
      }
    }
  },
  // 重新创建图层和cesium viewer 删除无人机数据
  hide_location_all() {
    let cesium_layer = this.g_cesium_layer()
    let viewer = cesium_layer.viewer()
    viewer.trackedEntity = null
    console.log('hide_location_all...this.uav.online', this.uav.online)
    // debugger
    Object.keys(this.uav.online).forEach((key) => {
      // debugger
      let item = this.uav.online[key]
      let entity = viewer.entities.getById(item.entity_id)
      console.log(item, 'item')
      console.log(entity, 'entity')
      // 如果是TD220，那么还有两个桨叶
      let low = viewer.entities.getById(item.entity_id + '_lower_rotar')
      let up = viewer.entities.getById(item.entity_id + '_upper_rotar')
      // item.positions = [];
      entity && viewer.entities.remove(entity)
      low && viewer.entities.remove(low)
      up && viewer.entities.remove(up)
      // entity.show = false
      // if (low) low.show = false
      // if (up) up.show = false
    })
  },
  // 显示航线
  show_airways(device, uav_data) {
    console.log('show_airways...显示航线 device', device)
    console.log('show_airways...显示航线 uav_data', uav_data)
    console.log('show_airways...显示航线', device._location)
    // debugger
    let entities = window.viewer.entities
    if (!device._location) {
      device._location = true
      this.uav_show_locations(device, uav_data)
    }
    // 显示航线
    let entity = entities.getById(`observe_polylineUav_${device.deviceHardId}`)
    console.log('entity', entity);

    entity && (entity.polyline.show = true)
    // entity && (currentPolylineEntity = entity)
  },
  hide_airways(device) {
    let entity = viewer.entities.getById(`observe_polylineUav_${device.deviceHardId}`)
    /* let entity = entities.getById(
                this.uav.online[device.deviceHardId].entity_id
              ); */
    entity && (entity.polyline.show = false)
  },
  // 删除地图上的航线数据
  hide_airways_all() {
    console.log(this.uav.online, 'this.uav.online 删除地图上的航线数据0')
    Object.keys(this.uav.online).forEach((key) => {
      let entity = viewer.entities.getById(`observe_polylineUav_${key}`)
      console.log(entity, 'entity 删除地图上的航线数据1')
      entity && window.viewer.entities.remove(entity)
      // if (entity) {
      //   entity.polyline.show = false
      // }
    })
    // debugger
  },
  // 显示位置 创建entity 模型和航线！！！
  show_locations(device, uav_data) {
    console.log(device, uav_data, 'show_locations...显示位置，创建位置entity')
    if (!uav_data) {
      // this.$el_message("暂未找到位置信息", () => { }, "error")
      return uav_data
    }
    let { deviceHardId } = device
    // 重新创建cesium
    console.log('重新创建cesium')
    let cesium_layer = this.g_cesium_layer()
    let viewer = window.viewer
    // 转换数据？
    // console.log('转换数据，作用是啥？')
    console.log('uav_data', uav_data);

    let data = this.uav_trans_location_datas(device, uav_data)
    console.log('转换数据，作用是啥 包含航线数据？？', data)
    let model = window.viewer.entities.getById(`observe_model_${deviceHardId}`)
    // console.log(model, '查找模型是否已经创建')
    if (!model) {
      console.log('如果模型没有创建,那么创建entity')
      this.uav.online[deviceHardId] = data
      this.uav.online[deviceHardId].entity_id = `observe_model_${data.device.deviceHardId}`
      console.log(this.uav.online, 'V! this.uav.online')
      if (!data.device['cateName']) {
        data.device['cateName'] = 'amp'
      }
      // 根据不同的无人机类型加载不同的模型
      // debugger
      // ！！！ 如果是 amp 加载的TD220 模型，包括机体，和两个叶浆，返回的是模型数组，机体，上叶浆和下叶浆
      // console.log(data.device['cateName'], 'VVB')
      if (data.device['cateName'] == 'amp') {
        let a = cesium_layer.add_model(window.viewer.entities, this.uav.online[deviceHardId], data.device)
        model = a[0]
        window.myBodyEntity = model
        // 模型位置平滑更新！！！
        model.position = new Cesium.CallbackProperty(this.uav_smoothingPosition, false)
        model.orientation = new Cesium.CallbackProperty(this.uav_smoothingYaw, false)
        a[1].position = new Cesium.CallbackProperty(this.uav_smoothingPosition, false)
        a[2].position = new Cesium.CallbackProperty(this.uav_smoothingPosition, false)
        a[1].orientation = new Cesium.CallbackProperty(this.uav_td220_liftingAirscrew, false)
        a[2].orientation = new Cesium.CallbackProperty(this.uav_td220_liftingAirscrew, false)
        // 还要处理模型姿态！！！
      } else {
        model = cesium_layer.add_model(window.viewer.entities, this.uav.online[deviceHardId], data.device)
        // 模型位置平滑更新！！！
        model.position = new Cesium.CallbackProperty(this.uav_smoothingPosition, false)
        model.orientation = new Cesium.CallbackProperty(this.uav_smoothingYaw, false)
      }
      console.log(model, '根据不同的无人机类型加载不同的模型')
      // window.myModel = model;
      // currentOnLineModel =
      // prePosition = this.uav.online[deviceHardId].position.clone()
      // debugger
    }
    console.log('查找航线entity')
    let polyline = window.viewer.entities.getById(`observe_polylineUav_${deviceHardId}`)
    console.log(polyline, '查找航线entity')
    // debugger
    if (!polyline) {
      console.log('如果航线不存在，则创建航线')
      console.log('data', data)
      data.positions = [];//重置航线
      cesium_layer.add_polyline(window.viewer.entities, {
        ...data,
        entity_id: `observe_polylineUav_${data.device.deviceHardId}`,
        options: { show: false }
      })
    }
    // 初始化定位
    console.log(uav_data.data.uavInfo, '初始化定位')
    let uInfo = uav_data.data.uavInfo
    let fPos = new Cesium.Cartesian3.fromDegrees(parseFloat(uInfo.longitude), parseFloat(uInfo.latitude), parseFloat(uInfo.height))
    viewer.camera.flyTo({
      destination: fPos,
      // maximumHeight: 10,
      complete: () => {
        viewer.trackedEntity = model
        model.viewFrom = new Cesium.Cartesian3(0, -700, 850)
      }
    })
  },
  remove_model(device) {
    // 删除飞控 无人机 模型航线
    if (data_source) {
      let entities = data_source.entities
      entities.removeById(`fckernel_model_${device.deviceHardId}`)
    }
    this.linevisible = false
    this.uav_hide_airway(device)
  },
  hide_location(device, uav_data) {
    viewer.trackedEntity = null
  },
  // 飞控链路mqtt，无人机位置信息更新
  update_location(device, uav_data) {
    // console.log(uav_data, '控链路mqtt数据');
    let online = this.uav.online[device.deviceHardId]
    // console.log(online, '控链路mqtt，无人机位置信息更新...在线模型modelEntity');
    if (online) {
      let data = this.uav_trans_mqtt_location_data({ device }, uav_data)
      // console.log(data, '飞控链路位置数据返回值');
      if (data?.position) {
        console.log(online.positions, 'online.positionsonline.positionsonline.positions')

        online.orientation = data.orientation
        online.position = data.position
        console.log(data.position, 'data.position')
        // console.log(data.position, '飞控链路mqtt，无人机位置信息更新，位置数据');
        // if (data.positions.length > 0) {
        //   online.positions.push(data.position)
        // }
        online.positions.push(data.position)
      }
    }
  },
  //TODO: 地面站数据，更新无人机位置信息
  update_locations(uav_data) {
    // window.yaw = uav_data.data.uavInfo.yaw
    // Bus.$emit('lonlat', {
    //   lon: uav_data.data.uavInfo.longitude,
    //   lat: uav_data.data.uavInfo.latitude
    // })
    // 无人机模型 entity
    let online = this.uav.online[uav_data.deviceHardId]
    // console.log('online', online);

    // window.online = online;
    // console.log(this.uav.online, 'this.uav.online这是？');
    // console.log(online, '无人机模型？')
    // this.uav.datalist = uav_data.data
    if (online) {
      // let data = this.uav_trans_location_datas({}, uav_data)
      // console.log('datadatadatadatadatadatadata', data);
      // 更新姿态和坐标
      let lng = parseFloat(uav_data.data.uavInfo.longitude) || 0
      let lat = parseFloat(uav_data.data.uavInfo.latitude) || 0
      let alt = parseFloat(uav_data.data.uavInfo.height) || 100
      let position = new Cesium.Cartesian3.fromDegrees(lng, lat, alt)
      window.myOnline = online
      // console.log('online', online);

      // let orientation = Cesium.Transforms.headingPitchRollQuaternion(position, new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(parseFloat(uav_data.data.uavInfo.yaw)), Cesium.Math.toRadians(parseFloat(uav_data.data.uavInfo.pitch)), Cesium.Math.toRadians(parseFloat(uav_data.data.uavInfo.roll))))
      // online.position = position
      // online.orientation = orientation
      // window.viewer.scene.forceRender();
      // 在这里每次数据更新，计算前一个坐标位置和后一个坐标位置的方位角，利用方位角计算模型的偏航角，也就是左右的偏转方向，控制模型头部的朝向
      // let en = window.viewer.entities.getById(`${online.entity_id}`)
      // en = undefined
      // if (en) {
      //   let ang = this.uav_bearing(prePosition, data.position)
      //   if (ang) {
      //     let h = Cesium.Math.toRadians(ang)
      //     let orientation = Cesium.Transforms.headingPitchRollQuaternion(data.position, new Cesium.HeadingPitchRoll(h, 0, 0))
      //     en.orientation = orientation
      //     // 本次坐标即为下一次的前一个坐标
      //     prePosition = data.position.clone()
      //   }
      // }
      // online.positions.push(online.position)
      // console.log(online.positions, 'XX_online.positions')

      // 1 查询航线entity
      // let a = currentPolylineEntity.polyline.positions._value
      // console.log('lng, lat, alt2', a);

      // if (a != undefined) {
      //   console.log('重新建立！！！')
      //   online.positions = []
      //   let data = this.uav_trans_location_datas(this.uav.control.device, uav_data)
      //   currentPolylineEntity.polyline.positions = new Cesium.CallbackProperty(() => {
      //     // console.log(data.positions, 'uav_data.positions')
      //     return online.positions
      //   }, false)
      // }
      // 更新航线！！！
      // console.log('position航线坐标更新', position);

      // console.log('online.positions', online.positions);
      // console.log('position', position);
      let myFlag = _.find(online.positions, position);
      if (!myFlag) {
        online.positions.push(position)
      }
    }
  },
  // 显示/创建航线
  async show_airway(device) {
    let polyline = entities.getById(`fckernel_polyline_${deviceHardId}`)
    if (!polyline) {
      cesium_layer.add_polyline(entities, {
        ...data,
        entity_id: `fckernel_polyline_${data.device.deviceHardId}`,
        options: {
          show: true
        }
      })
    }
  },
  hide_airway(device) {
    if (!data_source) {
      return false
    }

    let entities = data_source.entities
    let entity = entities.getById(`fckernel_polyline_${device.deviceHardId}`)
    entity && (entity.polyline.show = false)
    if (device.deviceHardId) {
      try {
        // 删除飞机模型
        window.viewer.entities.removeById(`fckernel_polyline_${device.deviceHardId}`)
      } catch (error) { }
    }
  },
  // 显示视频
  async show_video(device, orgName) {
    // 清空所有无人机控制的状态
    console.log(this.uav.list, 'this.uav.list')
    try {
      let device_list = tree2list(this.uav.list)
      device_list.forEach((item) => {
        if (item.deviceHardId !== device.deviceHardId && item._control) {
          item._video = false
          this.uav_hide_control(item)
        }
      })
      this.$set(this.uav.control, 'device', device)
    } catch (error) { }
    // 获取视频流
    let data = await API.FCKERNEL.GetDeviceStreamAddressByDeviceId({
      deviceHardId: device.deviceHardId
    })
    if (!data.list) {
      device._video = false
      return this.$message.info('暂无视频')
    } else {
      // 设置视频位置
      let b = document.querySelector('.uav_videoBox')
      b.style.top = '8%'
      let index = this.uav.videos.findIndex((item) => item === undefined)
      if (index !== -1) {
        this.uav.videos.splice(index, 1, { ...device, orgName, videos: data })
      } else {
        if (this.uav.videos.length < 4) {
          this.uav.videos = [{ ...device, orgName, videos: data }]
        } else {
          this.uav_hide_video(this.uav.videos[0])
          this.uav_show_video(device, uav_data)
        }
      }
    }
  },
  hide_video(device) {
    let { deviceHardId } = device
    let index = this.uav.videos.findIndex((item) => item && item.deviceHardId === deviceHardId)

    let devices = tree2list(this.uav.list)
    device = devices.find((item) => item.deviceHardId === deviceHardId)

    if (index !== -1) {
      device._video = false
      this.uav.videos.splice(index, 1)
    }
  },
  show_control(device) {
    // 先清空所有无人机控制的状态
    let device_list = tree2list(this.uav.list)
    device_list.forEach((item) => {
      if (item.deviceHardId !== device.deviceHardId && item._control) {
        item._control = false
        this.uav_hide_control(item)
      }
    })
    this.$set(this.uav.control, 'device', device)
  },
  hide_control(device) {
    this.uav.control.device = null
    this.uav.control.device = null
    this.uav.control.data = null
    this.uav.control.battery = null
    this.uav.control.mount = null
    this.uav.control.mounts = []
    this.uav.control.mounts = []
  },
  // 飞控链路，更新控制面板
  //TODO:地面站更新控制面板
  update_control(device, uav_data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      if (control_device.stationType == 1) {
        // console.log(uav_data, '飞控链路面板数据更新');
        // console.log(uav_data?.gps?.absoluteAlt, '飞行高度');
        // 飞控链路
        let uav_data_copy = _.cloneDeep(uav_data)
        uav_data_copy.longitude = uav_data?.locationCoordinate3D?.longitude ? uav_data.locationCoordinate3D.longitude : 0
        uav_data_copy.latitude = uav_data?.locationCoordinate3D?.latitude ? uav_data.locationCoordinate3D.latitude : 0
        // 飞行高度
        uav_data_copy.height = uav_data?.gps?.absoluteAlt || 0
        // 起点距离
        uav_data_copy.distanceToHome = uav_data?.distanceToHome ? uav_data.distanceToHome : 0;
        // 根据实际飞行距离和总距离计算剩余距离
        // console.log('airLineDistance', airLineDistance);
        // console.log('uav_data.flightDistance', uav_data.flightDistance);
        // console.log('this.onekeyTaskAirlineDistance', this.onekeyTaskAirlineDistance);
        // if (this.onekeyTaskAirlineDistance != 0) {
        //  uav_data_copy.remainDistance = parseFloat(this.onekeyTaskAirlineDistance) - uav_data.flightDistance; 
        //  uav_data_copy.onekeyTaskAirlineDistanceShowFlag = true; 
        // } else {
        //   uav_data_copy.remainDistance = this.onekeyTaskAirlineDistance; 
        // }
        console.log('this.uav.airLineDistance', this.uav.airLineDistance);
        if (this.uav.airLineDistance != '-') {
          this.uav_caculateLastDistance(uav_data_copy.longitude, uav_data_copy.latitude, device.distanceToNext);
          uav_data_copy.remainDistance = parseFloat(this.uav.airLineDistance) - uav_data.flightDistance; 
        }
        // onekeyTaskAirlineDistanceShowFlag
        // 海拔高度
        uav_data_copy.altitude = uav_data?.locationCoordinate3D?.altitude ? uav_data.locationCoordinate3D.altitude : 0
        // 飞行时间
        uav_data_copy.flightTime = uav_data?.flyTime ? uav_data.flyTime : 0
        uav_data_copy.flightTime = uav_data_copy.flightTime / 1000
        // 飞行里程
        uav_data_copy.flightDistance = uav_data?.flyDistance ? uav_data.flyDistance : 0
        // 飞行速度
        uav_data_copy.groundSpeed = uav_data?.groundSpeed ? uav_data.groundSpeed : 0
        // 电量，不在这更新
        // 爬升率，没有爬升率
        uav_data_copy.climbRate = 0
        // pitch
        uav_data_copy.pitch = uav_data?.attitude?.pitch ? uav_data.attitude.pitch : 0
        uav_data_copy.yaw = uav_data?.attitude?.yaw ? uav_data.attitude.yaw : 0
        // rcChannelState
        // console.log(uav_data_copy, '最终面板数据');
        this.$set(this.uav.control, 'data', uav_data_copy)
      } else {
        let uav_data_copy = _.cloneDeep(uav_data)
        // console.log('airLineDistance', airLineDistance);
        // console.log('uav_data.flightDistance', uav_data.flightDistance);
        // console.log('this.onekeyTaskAirlineDistance', this.onekeyTaskAirlineDistance);
        // if (this.onekeyTaskAirlineDistance != '-') {
        //   uav_data_copy.remainDistance = parseFloat(this.onekeyTaskAirlineDistance) - uav_data.flightDistance; 
        //   uav_data_copy.onekeyTaskAirlineDistanceShowFlag = true;
        // } else {
        //   uav_data_copy.remainDistance = this.onekeyTaskAirlineDistance; 
        // }
        // uav_data_copy.remainDistance = airLineDistance - uav_data.flightDistance;
        console.log('this.uav.airLineDistance', this.uav.airLineDistance);
        
        if (this.uav.airLineDistance != '-') {
          this.uav_caculateLastDistance(uav_data_copy.longitude, uav_data_copy.latitude, uav_data.distanceToNext);
          uav_data_copy.remainDistance = parseFloat(this.uav.airLineDistance) - uav_data.flightDistance; 
        }

        this.$set(this.uav.control, 'data', uav_data_copy)
      }
    }
    // console.log('更新无人机信息');
  },
  //TODO: 更新电池信息
  update_control_battery(device, battery_data) {
    // console.log('更新电池信息', battery_data)
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      // 更新电池
      // console.log(battery_data, '更新电池信息')
      if (control_device.stationType == 1) {
        // 把飞控链路数据的电池字段统一到地面站  chargeRemaining ---> battaryRemain
        // console.log('2 飞控链路更新电池信息', battery_data);
        // console.log('3 飞控链路更新电池', battery_data.chargeRemaining);
        let battery_data_copy = _.cloneDeep(battery_data)
        battery_data_copy.battaryRemain = battery_data_copy.chargeRemaining
        this.$set(this.uav.control, 'battery', battery_data_copy)
      } else {
        this.$set(this.uav.control, 'battery', battery_data)
      }
    }
  },
  update_channelData(device, channelData) {
    // if (this.uav.control.device.deviceHardId === device.deviceHardId) {
    this.$set(this.uav.control, 'channelData', channelData)
    // }
  },
  // 无人机提示信息
  update_control_info(device, battery_data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      this.uav.control.info.push(battery_data)
    }
  },
  update_mount(device, data) {
    let control_device = this.uav.control.device
    if (control_device && control_device.deviceHardId === device.deviceHardId) {
      let mount = this.mount.list.find((item) => item.name === data.gimbalName)
      if (mount) {
        let find_item = this.uav.control.mounts.find((item) => item.name === data.gimbalName)
        if (!find_item) {
          this.uav.control.mounts.push({
            ...data,
            ...mount
          })
        }
      }
    }
  },
  // 更新挂载信息
  update_mounts(device, data) {
    if (!data) {
      return
    }
    this.uav.control.mounts = data
    // console.log(this.mount.list, 'this.mount.list');
    // console.log(this.uav.control.mounts, 'this.uav.control.mounts');
    this.mount.list.forEach((item, i) => {
      this.uav.control.mounts.forEach((val, j) => {
        if (val.mountName === item.name) {
          val.name = item.name
          val.icon = item.icon
          val.component = item.component
        }
      })
    })
  },
  change_mount(mount) {
    this.$set(this.uav.control, 'mount', mount)
  },
  mount_directive(data) {
    let mount = this.uav.control.mount

    if (mount) {
      this.uav_mqtt_fn({
        type: 514,
        data: {
          mountId: mount.mountId,
          payload: data
        }
      })
    }
  },
  // 视频指点命令
  move_directive(data) {
    // debugger
    // data.mountId && 不需要mountId
    if (data.data) {
      if (this.wsShow) {
        let sendData = {
          type: 200,
          // systemCode: 'mmc',
          systemCode: 'uatair',
          state: 1,
          username: this.user_info && this.user_info.username,
          height: 0,
          idlingTime: 0,
          data: {
            // cmdControlType: 7000,
            cmdFunction: parseInt(7000),
            cmdState: null,
            // cmdValue: null,
            // cmdBuffer: data.data.join(','),
            // cmdName: data.name,
            cmdName: 'UAT_MOUNT_Z40TIR',
            method: data.newData.method,
            cmdValue: {
              x: data.newData.x,
              y: data.newData.y
            }
          },
          deviceHardId: data.deviceHardId
        }
        // debugger
        console.log(sendData, '指点控制')
        this.uav_send_ws(sendData)
      } else {
        this.uav_mqtt_fn({
          type: 514,
          data: {
            mountId: data.mountId,
            payload: data.data
          }
        })
      }
    }
  },

  /**
   * 切换无人机模式
   * { type: 513, data: mode }
   * @param {*} data
   */
  change_mode(mode) {
    this.uav_mqtt_fn({ type: 513, data: mode.data })
  },

  update_org_status(cate, id) {
    let list = tree2list_org(this.uav.list)
    let org_id = id
    while (org_id) {
      let org = list.find((x) => x.id === org_id)
      if (org) {
        if (cate === 1) {
          org.offline -= 1
          org.online += 1
        }
        if (cate === 0) {
          org.offline += 1
          org.online -= 1
        }
        org_id = org.pid
      } else {
        org_id = null
      }
    }
  },

  /**
   * cate { -1: 离线, 1: 上线 }
   * @param {*} cate
   * @param {*} deviceHardId
   */
  update_device_report_status(cate, deviceHardId) {
    let devices = tree2list(this.uav.list)
    let did = this.uav.control.device && this.uav.control.device.deviceHardId
    devices.map((item) => {
      if (item.deviceHardId == deviceHardId) {
        if (item.reportStatus !== cate) {
          item.reportStatus = cate
          if (cate === 1) {
            this.uav_update_org_status(1, item.org_id)
          } else if (cate == 0) {
            this.uav_update_org_status(0, item.org_id)
            item._control = false
            // // 视频推流状态关闭
            // item._videoStatus = 0
            if (did && did == deviceHardId) {
              this.uav.control = {}
            }
          }
        }
      }
    })
  },

  /**
   * 无人机 mqtt 发送指令
   * @param {*} data
   */
  mqtt_fn(data, type, device = this.uav.control.device) {
    // debugger
    if (device) {
      console.log('无人机指令', data)
      let uavCate = device.cateName == 'px4' ? 'PX4' : 'APM'
      // let uavCate = device.cateName == 'PX4'
      uavCate = 'uaq20'
      let topic = `${uavCate}/OBTAIN/${device.deviceHardId}`
      try {
        window.$log('mqtt指令---------', topic, '----------', data)
      } catch (e) { }
      this.mqtt_publish(topic, data, (e) => {
        console.log('发送指令', data, 'topic', topic)
      })
    }
  },

  // 计算方位角 p0 p1 分别为 Cartesian3 格式 返回单位 度 °
  bearing(p0, p1) {
    let p00 = this.uav_c3toDegrees(p0)
    let p01 = this.uav_c3toDegrees(p1)
    let a = p01.lon - p00.lon
    let b = p01.lat - p00.lat
    let c = p01.height - p00.height
    // console.log(p00, 'p00');
    // console.log(p01, 'p01');
    // console.log(a, b, c, 'ccccccccccccccccc');
    if (Math.abs(a) < 1e-6 || Math.abs(b) < 1e-6) {
      return null
    }
    let startLat = p00['lat']
    let startLng = p00['lon']
    let destLat = p01['lat']
    let destLng = p01['lon']
    let y = Math.sin(destLng - startLng) * Math.cos(destLat)
    let x = Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng)
    let brng = Math.atan2(y, x)
    let brngDgr = Cesium.Math.toDegrees(brng)
    return (brngDgr + 360) % 360
  },
  // 笛卡尔坐标转经纬度
  c3toDegrees(c3) {
    //笛卡尔世界坐标转经纬度弧度坐标
    let cartographic = window.viewer.scene.globe.ellipsoid.cartesianToCartographic(c3)
    //经纬度弧度坐标转经纬度坐标
    let lon = Cesium.Math.toDegrees(cartographic.longitude)
    let lat = Cesium.Math.toDegrees(cartographic.latitude)
    let height = cartographic.height
    return { lon: lon, lat: lat, height: height }
  },

  //******************************* 无人机状态信息 websocker **************************************************** //
  /**
   *@Description: 接收各种状态信息 无人机离线，在线
   *@Author: name
   *@Date: 2023-11-09 14:26:51
   *@Params1:
   *@Return1:
   */
  init_ws_status() {
    let { user_info } = this
    let _this = this
    let username = user_info.username
    let token = user_info['mmc-identity'] + user_info['FLYINGSESSIONID']
    let appId = user_info.appid
    let ws_url = process.env.VUE_APP_WS_URL_ALARM
    let ws_status = (this.uav.ws_status = new WebSocket(ws_url))
    let params = {
      type: 100,
      systemCode: 'mmc',
      state: 1,
      username,
      token,
      appId
    }
    ws_status.onopen = (e) => {
      console.log(`${ws_url} 已连接`, e)
      ws_status.send(JSON.stringify(params))
    }
    ws_status.onmessage = (e) => {
      // console.log(`飞行监控无人机状态websocket${ws_url}接收数据`, e);
      try {
        let metadata = null
        // console.info(e, `${ws_url} 飞行监控状态信息原始数据`);
        metadata = JSON.parse(e.data)
        // 无人机离线在线等状态信息检查
        this.uav_checkOnLineStatus(metadata)
      } catch (error) {
        console.info(error, `${ws_url} 接收消息失败`)
      } finally {
      }
    }
    ws_status.onerror = (error) => {
      console.log(`${ws_url} 连接错误`, error)
    }
    ws_status.onclose = (data) => {
      console.log(`${ws_url} 连接关闭`, data)
    }
  },
  /**
   *@Description: 无人机状态信息检查
   *@Author: name
   *@Date: 2023-11-13 14:23:24
   *@Params1:
   *@Return1:
   */
  checkOnLineStatus(metadata) {
    if (!metadata) return
    // console.log(metadata, '无人机状态信息');
    // console.log(this.uav.list, '无人机列表信息');
    // console.log(this.onUavSearch, 'onUavSearch');
    // this.onUavSearch();
    if (metadata.msgnum === 4139) {
      // console.log(metadata.data.deviceHardId + ' 设备状态更新通知---4139');
      this.uav_checkListData(metadata.data)
    }
  },
  /**
   *@Description: 检查列表数据在线离线状态
   *@Author: name
   *@Date: 2023-11-13 15:45:05
   *@Params1:
   *@Return1:
   */
  checkListData(wsData) {
    let _this = this
    let data = this.uav.list
    for (let i = 0; i < data.length; i++) {
      childFn(data[i])
      deviceFn(data[i])
    }
    function childFn(data) {
      let nchild = data.child
      if (nchild && nchild.length != 0) {
        for (let i = 0; i < nchild.length; i++) {
          for (let j = 0; j < nchild[i].devices.length; j++) {
            // console.log(nchild[i].devices[j], 'childFn');
            if (wsData.deviceHardId == nchild[i].devices[j].deviceHardId) {
              nchild[i].devices[j].reportStatus = parseInt(wsData.onLine)
              _this.uav_afterOffLine(wsData)
            }
          }
          childFn(nchild[i])
        }
      }
    }
    function deviceFn(data) {
      let devices = data.devices
      for (let m = 0; m < devices.length; m++) {
        // console.log(devices[m], 'deviceFn');
        if (wsData.deviceHardId == devices[m].deviceHardId) {
          devices[m].reportStatus = parseInt(wsData.onLine)
          _this.uav_afterOffLine(wsData)
        }
      }
    }
  },
  /**
   *@Description: 当前页面无人机离线、在线
   *@Author: name
   *@Date: 2023-11-13 16:01:31
   *@Params1:
   *@Return1:
   */
  afterOffLine(wsData) {
    // console.log(this.uavId, 'this.uavId');
    // console.log(wsData, 'wsDatawsDatawsDatawsData');
    if (this.uavId.deviceHardId == wsData.deviceHardId) {
      if (parseInt(wsData.onLine) == 0) {
        // this.$message.warning(`${this.uavId.deviceName} 已离线`)
      } else {
        // this.$message.success(`${this.uavId.deviceName} 已上线`)
      }
      Bus.$emit('uavOnLineFlagFn', parseInt(wsData.onLine))
    }
  },
  /** 添加备降点到地图上
   *@Description:
   *@Author: name
   *@Date: 2024-01-12 16:22:26
   *@Params1:
   *@Return1:
   */
  addStandByPoint2Map(device, v) {
    let defaultEn = new Cesium.Entity({
      id: `f_standBy_point_default_id`,
      position: new Cesium.Cartesian3.fromDegrees(v.backup.longitude, v.backup.latitude),
      point: {
        pixelSize: 12,
        color: Cesium.Color.YELLOW,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        disableDepthTestDistance: 99000000
      },
      label: {
        text: `备用备降点`,
        show: true,
        showBackground: false,
        font: '10px monospace bolder',
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        pixelOffset: new Cesium.Cartesian2(0, -15),
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        disableDepthTestDistance: 99000000,
        fillColor: Cesium.Color.fromCssColorString('#FFFF00') // 字体颜色
      }
    })
    let standByEn = new Cesium.Entity({
      id: `f_standBy_point_standby_id`,
      position: new Cesium.Cartesian3.fromDegrees(v.default.longitude, v.default.latitude),
      point: {
        pixelSize: 12,
        color: Cesium.Color.YELLOW,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        disableDepthTestDistance: 99000000
      },
      label: {
        text: `默认备降点`,
        show: true,
        showBackground: false,
        font: '10px monospace bolder',
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        pixelOffset: new Cesium.Cartesian2(0, -15),
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        disableDepthTestDistance: 99000000,
        fillColor: Cesium.Color.fromCssColorString('#FFFF00') // 字体颜色
        // translucencyByDistance: new Cesium.NearFarScalar(1000, 0, 100000, 1)
      }
    })
    window.viewer.entities.add(defaultEn)
    window.viewer.entities.add(standByEn)
  },
  /** 删除备降点
   *@Description:
   *@Author: name
   *@Date: 2024-01-12 16:38:29
   *@Params1:
   *@Return1:
   */
  deleteStandByPoint() {
    let en1 = window.viewer.entities.getById(`f_standBy_point_default_id`)
    let en2 = window.viewer.entities.getById(`f_standBy_point_standby_id`)
    en1 && window.viewer.entities.remove(en1)
    en2 && window.viewer.entities.remove(en2)
  },
  /**@Description :  ******************************************* 计算当前无人机位置到航线终点的位置
   **@Date: 2024-06-14 14:00:17
  */
  caculateLastDistance(currentLon, currentLat, nextDistance){
    // let len = this.uav.airLinePoints.length;
    // console.log('this.uav.airLinePoints', this.uav.airLinePoints, len);
    // let diffArray = [];
    // for (let i = 0; i < len; i++){
    //   let point = this.uav.airLinePoints[i];
    //   console.log('point', point);
    //   let from = turf.point([currentLon, currentLat]);
    //   let to = turf.point([point.lon, point.lat]);
    //   let options = {units: 'kilometers'};
    //   let dis = parseFloat(turf.distance(from, to, options) * 1000);
    //   console.log('dis', dis);
    //   // console.log('当前距离：i: ' + i + ' --- dis: ' + dis + ' --- nextDistance: ' + nextDistance + '--- diff: ' + Math.abs(dis - nextDistance)); 
    //   diffArray.push(Math.abs(dis - nextDistance));
    // }
    // var min = Math.min(...diffArray);
    // let minIndex = diffArray.findIndex((item, index) => {
    //   return item == min;
    // });
    // console.log(`下一个点是第${minIndex}个点`);//2
  },
}
